.executiveDetailsModal {
  padding: 6.9rem 7.3rem 9.37rem 6.1rem;
  width: 87.5rem;
}

/*Header*/
.modalHeader {
  display: flex;
  justify-content: space-between;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
  margin: 0;
}
.modalDesc {
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 1.85rem 0 2.55rem 0;
  opacity: 0.65;
  width: 60.4rem;
}

/*Modal body*/
.modalBody {
  display: flex;
}
.executiveDetailsLabel {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 0.3rem;
}
.executiveDetailsValue {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.6rem;
  margin: 0;
  margin-bottom: 3.65rem;
}
.modalBodyLeftContainer {
  border-right: 0.1rem solid var(--color-primary-82);
  margin-right: 5.87rem;
  width: 50%;
}
.projectDetailsContainer {
  margin-top: 2.95rem;
}
.ProjectDetailsHeading {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 2rem;
}
.assignedProjectName {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1.7rem;
  line-height: 2.3rem;
  margin: 0;
}
.assignedProjectDate {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 3.3rem;
}
