.summary-component {
  display: inline-flex;
  flex-direction: column;
}
.summary-component__header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.summary-component__body {
  color: var(--color-black);
  font-size: 6rem;
  line-height: 4.5rem;
  padding-top: 2rem;
  margin-bottom: 0.5rem;
}

.summary-component__footer {
  color: var(--color-primary-56);
  font-size: 1.5rem;
  line-height: 2rem;
}

.status-success {
  color: var(--color-status-success);
}

.status-error {
  color: var(--color-status-error);
}

.status-warning-low {
  color: var(--color-status-warning-low);
}
