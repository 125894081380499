/*Header*/
.header {
  margin-top: 3.55rem;
}
.activeCpListHeader {
  display: flex;
  justify-content: space-between;
}
.addCpBtnContainer {
  width: 25.8rem;
}

/*Main*/

.filterContainer {
  align-items: center;
  display: flex;
}
.searchBox {
  margin-right: 2rem;
  margin-top: -5rem;
  max-width: 50rem;
}
.filterTitle {
  color: var(--color-primary-82);
  font-size: 2rem;
  line-height: 2.4rem;
  margin-right: 0.5rem;
}
.filterIcon {
  color: var(--color--secondary-205);
}
.tabsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
}
.tabContainerHeader {
  border-bottom: var(--primary-border);
  color: var(--color-primary-56);
  display: flex;
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.tabItem {
  cursor: pointer;
  padding-bottom: 1.75rem;
  text-align: center;
  width: 18.5rem;
}
.activeTab {
  border-bottom: 0.4rem solid var(--color-primary-41);
  font-weight: bold;
}
.disabledTab {
  color: var(--color-primary-82);
  pointer-events: none;
}
.tabContainerBody {
  overflow: hidden;
  padding-right: 2rem;
}
