.user-list-container__header {
  color: var(--color--secondary-205);
  line-height: 3.7rem;
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 1.9rem;
}

.user-list-container {
  margin-top: 6.4rem;
}
