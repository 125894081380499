.leadContainer {
  align-items: center;
  border-bottom: 1px solid var(--color-cp-primary-96);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0.7rem 0;
}

/* Lead */
.leadName {
  color: var(--color-cp-primary-31);
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
}
.mobile {
  color: var(--color-cp-primary-43);
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
.addressWrap {
  align-items: center;
  display: flex;
  max-width: 85%;
}
.cpLead{
  flex-basis: 70%;
}
/* Status Icon */
.statusIcon.iconRed {
  color: var(--color-status-error);
}
.statusIcon.iconPaleBlue {
  color: hsla(186, 100%, 43%, 1);
  /* TODO: Color is not generic */
}
.statusIcon.iconOrange {
  color: var(--color-cp-status-warning);
}
.statusIcon {
  font-size: 1.2rem;
  margin-right: 0.4rem;
}

.adress {
  -webkit-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--color-cp-primary-43);
  display: -webkit-box;
  font-size: 1rem;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* QR Code */
.leadQRCode img {
  width: 100%;
  display: block;
}
.leadQRCode {
  flex-basis: 20%;
  font-weight: bold;
  padding: 0.2rem;
}
