.imageCard {
  margin-right: 1.1rem;
  position: relative;
}

.imageCard img {
  border-radius: 1rem;
  display: block;
  min-width: 15rem;
  max-width: 15rem;
  width: 100%;
  min-height: 17rem;
  max-height: 17rem;
}

.cardDescription {
  background: hsla(0, 27%, 2%, 0.2);
  /*TODO: Color not generic*/
  border-radius: 1.2rem;
  bottom: 0;
  left: 0;
  padding: 0 1rem 1rem 1rem;
  position: absolute;
  right: 0;
}

.cardTitle {
  color: var(--color-primary-100);
  font-size: 1.3rem;
  font-family: 'SF-UI-semibold', sans-serif;
}

.subTitle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--color-cp-primary-91);
  display: -webkit-box;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'SF-UI-regular', sans-serif;
}

.headerWrap {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 1.3rem;
}

.viewAll {
  color: var(--color-cp-primary-61);
  display: flex;
  flex-basis: 15%;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  justify-content: flex-end;
}
