.containerWrap {
  max-height: 90vh;
  overflow: scroll;
  padding: 2rem 1.8rem;
  position: relative;
}
.headerWrap {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  padding-top: 1.3rem;
}
.headerTitleWrap {
  background-color: var(--color-primary-100);
  border-bottom: 1px solid var(--color-cp-primary-91);
  display: flex;
  flex-flow: column;
  left: 0;
  margin: 0 auto;
  max-width: 90%;
  padding: 2rem 0rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-1);
}
.itemsWarp {
  padding-top: 11rem;
}
.profileImage img {
  border-radius: 100%;
  display: block;
  width: 100%;
}
.profileImage {
  max-width: 60px;
}
.profileImage > div {
  opacity: 1;
}
.titleWrap {
  flex-basis: 70%;
  margin-left: 2rem;
}

.headTitle {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-bold', sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
}
.subTitle {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.2rem;
}
.iconWrap {
  align-items: center;
  display: flex;
  position: relative;
  width: 90%;
}
.rightArrowIcon {
  font-size: 2rem;
  color: var(--color-cp-primary-17);
}
.detailsSubText {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  font-size: 1.2rem;
  color: var(--color-cp-primary-43);
}
.profileIcon {
  background-color: var(--color-cp-primary-96);
  border-radius: 0.6rem;
  color: var(--color-cp-primary-17);
  font-size: 2rem;
  padding: 0.8rem;
}
.detailsType {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}
.dataDetails {
  margin: 0 2rem;
}
.contentsWrap {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 3.3rem 0;
}
.contentsWrap:last-child .arrowIcon {
  display: none;
}
