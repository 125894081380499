.imageGalleryWrap {
  padding-bottom: 2rem;
}

.imageGalleryWrap .imageCard {
  border-radius: 0.5rem;
  display: block;
  margin-left: 1rem;
  margin-right: 0;
  max-height: 16.8rem;
  max-width: 28rem;
  min-height: 16.8rem;
  min-width: 28rem;
  width: 100%;
}
