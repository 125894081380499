.admin-login {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  background-image: url(https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/login-background.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.admin-login .login-form {
  max-width: 50rem;
  background: white;
  border-radius: 1rem;
}

.admin-login .cogni-logo {
  position: fixed;
  right: 5rem;
  bottom: 5rem;
  font-size: 7rem;
  color: white;
}
