.contentWrap {
  display: flex;
  flex-flow: column;
  padding: 2rem 2rem 0 2rem;
}
.closeIcon {
  color: var(--color-cp-primary-17);
  font-size: 2rem;
  text-align: end;
}
.headerWrap {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}
.headerWrap img {
  display: block;
  margin-right: 1rem;
  max-width: 8rem;
  width: 100%;
}
.nameWrap {
  display: flex;
  flex-flow: column;
}
.leadsName {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
}
.statusIcon.iconRed {
  color: var(--color-status-error);
}

.statusIcon.iconPaleBlue {
  color: hsla(186, 100%, 43%, 1);
  /* TODO: Color is not generic */
}

.statusIcon.iconOrange {
  color: var(--color-cp-status-warning);
}

.statusIcon {
  font-size: 1.2rem;
  margin-right: 0.4rem;
}
.timeSpan {
  align-items: baseline;
  color: var(--color-cp-primary-43);
  display: flex;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
}
.detailsType {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
}
.boldText {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-bold', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  max-width: 90%;
}
.mainWrap {
  max-height: 70vh;
  overflow-y: scroll;
}
.detailsWrap {
  display: flex;
  flex-flow: column;
  margin-bottom: 2.3rem;
}
.horizontalLine {
  border-bottom: 1px solid hsla(0, 2%, 89%, 1);
}
.siteTitle {
  color: var(--color-cp-primary-61);
  font-family: 'SF-UI-bold', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 2.3rem;
}
.noSiteVisit {
  font-size: 1.3rem;
}
.visitDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.2rem;
  margin-top: 2rem;
}
.visitWrap,
.dateWrap {
  display: flex;
  flex-flow: column;
  width: -webkit-fill-available;
}
.dateWrap {
  margin-left: 5vw;
}
.cpLeadSiteVisit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.3rem;
  margin-top: 1rem;
}
.cpLeadInfo {
  align-items: center;
  display: flex;
}
