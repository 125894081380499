.projectRenewalModal {
  padding: 4.2rem 6.6rem 1.4rem 6.6rem;
  width: 44.3rem;
}

/*Header*/
.modalHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 3.8rem;
  margin: 0;
}
.closeIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.headingAndCloseIconContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.05rem;
}
.modalDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  opacity: 0.65;
}

/*Body*/
.modalBody {
  margin-top: 4.55rem;
}
.projectDetailsText {
  color: var(--color-primary-82);
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin: 0;
  margin-bottom: 0.4rem;
}
.projectName {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 2.2rem;
  line-height: 2.7rem;
  margin: 0;
  margin-bottom: 0.4rem;
}
.textInputContainer {
  margin-top: -1.2rem;
}
.dropDownContainer {
  margin-top: 2.4rem;
}
.developerName {
  color: var(--color-primary-56);
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 0;
}
.modalFooter {
  margin-top: 5.15rem;
}
.footerDisclaimer {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 1rem;
  line-height: 1.3rem;
  margin: 0;
  margin-bottom: 1.25rem;
}
.downloadReceiptBtn {
  margin-top: 1rem;
}
