.allDetailsContainer {
  background-color: var(--color-primary-98);
  display: flex;
  flex-wrap: wrap;
  padding: 5.4rem 10.8rem 3rem 10.8rem;
}
.individualDetailContainer {
  margin-bottom: 4.2rem;
  margin-right: 6.3rem;
  width: 28.1rem;
}
.detailLabel {
  color: var(--color-primary-82);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin: 0;
  margin-bottom: 1rem;
}
.detailValue {
  color: var(--color-primary-56);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin: 0;
}
.detailValue[data-preview="true"] {
  cursor: pointer;
}
.modalAdditionalClasses{
  width: max-content;
}