.gridContainer {
  margin-top: 3rem;
}

.lastActionName {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin: 0;
  margin-bottom: 1rem;
}
.lastActionDate {
  color: var(--color-primary-82);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0;
}
