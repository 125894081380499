.container {
  position: relative;
  font-size: 1.3rem;
  color: var(--color-cp-primary-43);
  line-height: 2rem;
}
.readMoreLessBtn {
  font-size: 1.3rem;
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-medium', sans-serif;
  font-weight: 600;
}
