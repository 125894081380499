.agGridContainer {
  box-shadow: 0px 0px 20px hsla(205, 20%, 32%, 0.08);
  /*TODO:Shadow color not generic*/
}

.projectsColumnDataContainer {
  margin-top: 2rem;
}

.statusDropdownContainer {
  width: 20.9rem;
}

.actionIcon {
  color: var(--color-primary-56);
  font-size: 3.7rem;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
}

.actionIconLable {
  color: var(--color-primary-82);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

.actionIconContainerProjectDisabled .actionIcon,
.actionIconContainerProjectDisabled .actionIconLable {
  color: var(--color-status-error);
}

/*Project disable state*/
.projectDisabled {
  color: var(--color-primary-70);
}

.BlockedState {
  color: var(--color-status-error);
}

.iconDisabble {
  color: var(--color-primary-70);
  cursor: not-allowed;
  pointer-events: none;
}
