.containerWrap {
  display: grid;
  grid-gap: 1.9rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 3rem;
}
.shareIcon:focus,
.shareIcon:active {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.collateralWrapper {
  border-radius: 1rem;
  border: 1px solid var(--color-cp-primary-83);
  padding: 1.7rem 1.5rem;
}

.iconWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.colletralIcon {
  border-radius: 0.6rem;
  font-size: 1.7rem;
  padding: 0.6rem;
}

.shareIcon {
  color: var(--color-cp-primary-53);
  font-size: 1.6rem;
}

.iconWrapper .view_in_ar {
  background-color: var(--color-cp-green-97);
  color: var(--color-cp-green-68);
}

.iconWrapper .description {
  background-color: var(--color-cp-saffron-97);
  color: var(--color-cp-saffron-75);
}

.iconWrapper .photo_size_select_actual {
  background-color: var(--color-cp-orange-97);
  color: var(--color-cp-orange-67);
}

.iconWrapper .menu_book {
  background-color: var(--color-cp-blue-98);
  color: var(--color-cp-blue-61);
}
/* TODO: Color is not generic */
.iconWrapper .movie {
  background-color: hsl(0deg 52% 67% / 21%);
  color: hsl(0deg 83% 49%);
}

.title {
  color: var(--color-cp-primary-35);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
}

.subTitle {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.2rem;
}
