.dashboard {
  padding-top: 6rem;
}

.dashboard-header {
  border-bottom: var(--primary-border);
  padding-bottom: 5.35rem;
}
.dashboard__welcome-title {
  font-size: 2.5rem;
  line-height: 3rem;
}

.dashboard__heading {
  margin-top: 3.4rem;
  display: flex;
  gap: 6.2rem;
  max-height: 39.4rem;
}

.dashboard__heading-label {
  flex-basis: 51%;
}

.dashboard__heading-title {
  color: var(--color-primary-56);
  font-size: 6.5rem;
  line-height: 8.1rem;
  line-height: 8.5rem;
  text-transform: uppercase;
  font-family: Montserrat;
}

.dashboard__heading-desc {
  margin-top: 1rem;
  color: var(--color-primary-70);
  line-height: 2.2rem;
  font-size: 1.5rem;
  /* max-width: 59rem; */
}

.dashboard__heading-banner {
  flex-basis: 49%;
}

.dashboard__heading-banner img {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

/* Sections */
.dashboard-section {
  margin-top: 3.95rem;
}

.dashboard-section__heading {
  margin-bottom: 3rem;
}

.dashboard-section__heading-title {
  font-size: 1.8rem;
  color: var(--color-primary-56);
  line-height: 2.2rem;
}

.dashboard-section__heading-desc {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--color-primary-70);
}

.dashboard-section__body {
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 2rem;
}

.dashboard-section__body-disable-horizontal-scroll {
  overflow-x: inherit;
  white-space: inherit;
}

/* Yout Home Card */
.booked-unit-cart-card {
  background: var(--color-primary-100);
  border-radius: 1rem;
  border: var(--primary-border);
  display: inline-flex;
  flex-direction: column;
  padding: 4rem;
  max-width: 52.2rem;
  margin-right: 3rem;
  cursor: pointer;
}

.booked-unit-cart-card__body {
  display: flex;
  gap: 3rem;
  border-bottom: 0.2rem solid hsla(0, 0%, 97%, 1);
  padding-bottom: 2.45rem;
  color: var(--color-primary-56);
  font-size: 1.8rem;
}

.booked-unit-cart-card__details {
  line-height: 2.2rem;
  flex-basis: 50%;
}

.booked-unit-cart-card__details-item {
  margin-bottom: 0.7rem;
}

.booked-unit-cart-card__amount {
  flex-basis: 50%;
  text-align: right;
}

.booked-unit-cart-card__amount-all-inclusive {
  font-size: 3.5rem;
  line-height: 4.2rem;
  color: var(--color-accent);
}

.booked-unit-cart-card__amount-label {
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.booked-unit-cart-card__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.75rem;
  font-size: 2rem;
  color: var(--color-status-incomplete);
}

.booked-unit-cart-card__status--complete {
  color: var(--color-status-success);
}

.booked-unit-cart-card__status .icon {
  font-size: 5.7rem;
}

/* Summary Card */
.summary-card {
  background: var(--color-primary-100);
  border-radius: 1rem;
  border: var(--primary-border);
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 33.7rem;
  min-height: 23rem;
  margin-right: 3rem;
}

.summary-card__header {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--color-primary-70);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-card__header .icon {
  font-size: 2.5rem;
  line-height: 3rem;
}

.summary-card__body {
  padding: 2.5rem 3.3rem 1.2rem 3.3rem;
}

.summary-card__unit-details {
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--color-primary-56);
  font-weight: bold;
  margin-bottom: 2.4rem;
}

.summary-card__status {
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  gap: 0.85rem;
}

.warning-low {
  color: var(--color-status-warning-low);
}

.warning-high {
  color: var(--color-status-warning-high);
}

.summary-card__status .icon {
  font-size: 2.5rem;
}

.summary-card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 3.3rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
  background: var(--color-primary-98);
}

/* Site Visit Item */

.site-visit-item {
  display: flex;
  padding: 3rem;
  border: var(--primary-border);
  border-radius: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.site-visit-item--schedule {
  cursor: pointer;
}

.site-visite-item__details-container {
  display: flex;
}

.site-visit-item__label-time-container {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.site-visit-item__label {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--color-primary-70);
}

.site-visit-item__time {
  font-size: 2.2rem;
  line-height: 2.7rem;
  color: var(--color-primary-56);
  font-weight: bold;
  max-width: 30rem;
}

.site-visit-item__address {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-primary-70);
  max-width: 30rem;
  margin-left: 3rem;
}

.site-visit-item__action-container {
  display: flex;
  gap: 3rem;
}

.site-visit-item .site-visit-type-icon {
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: var(--color-accent);
}
