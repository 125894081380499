.container {
  /* background-image: url('paper.gif'); */
  background-color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
}

.container header {
  color: #ffffff;
  font-family: PerfectlyAmicable, sans-serif;
  font-size: 5rem;
  line-height: 4.4rem;
  text-transform: lowercase;
}

.container main li {
  color: rgba(121, 121, 121, 1); /* TODO: Make color generic as per theme */
  font-size: 1.8rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 1.795rem;
}

.desc {
  color: rgba(49, 49, 49, 1); /* TODO: Make color generic as per theme */
  font-size: 6.2rem;
  font-family: PerfectlyAmicable, sans-serif;
  line-height: 4.4rem;
  margin-bottom: 3.3rem;
}

.getStartedBtn {
  margin-bottom: 5.7rem;
  margin-top: 6.7rem;
}
