.gridContainer {
  margin-top: 2.2rem;
}
.cpName {
  color: var(--color-primary-56);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin: 0;
}
.reqSubmittedDate {
  color: var(--color-primary-82);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 0.6rem;
}
