.container {
  /* background-image: url('paper.gif'); */
  background-color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 2rem; */
  width: 100%;
}

.container header {
  color: #ffffff;
  font-family: PerfectlyAmicable, sans-serif;
  font-size: 5rem;
  line-height: 4.4rem;
  padding: 2rem;
  text-transform: lowercase;
}

.container main {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1rem 1rem 0 0;
  padding-bottom: 6.2rem;
}

.termsAndConditions {
  color: rgba(112, 112, 112, 1); /* TODO: Color is not generic */
  font-size: 1rem;
  margin-top: 1.6rem;
}
.termsAndConditions a {
  color: var(--color-cp-primary-17);
  font-weight: bold;
  text-decoration: none;
  transition: all 0.4s ease;
}
.termsAndConditions a:hover {
  color: var(--color-primary-34);
}
