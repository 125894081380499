.tab-container {
  display: flex;
  flex-direction: column;

  .tab-container__header {
    display: flex;
    font-size: 1.8rem;
    color: var(--color-primary-56);
    line-height: 2.2rem;
    border-bottom: var(--primary-border);

    .tab-container__header-item {
      width: 18.5rem;
      padding-bottom: 1.75rem;
      cursor: pointer;
      text-align: center;

      &.active {
        font-weight: bold;
        border-bottom: 0.4rem solid var(--color-primary-41);
      }
      &.disabled {
        // cursor: not-allowed;
        pointer-events: none;
        color: var(--color-primary-82);
      }
    }
  }
  .tab-container__body {
    overflow: hidden;
    padding-right: 2rem;
  }
}

.active-leads {
  padding-top: 4.65rem;
  .active-leads-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .legend {
      color: var(--color-primary-70);
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 500;
      width: 43.2rem;
    }
    .indicators {
      display: flex;
      align-items: center;
      gap: 1rem;
      .indicator-title {
        display: flex;
        flex-direction: column;
        color: var(--color-primary-70);
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-weight: 500;
      }
    }
    .filter-panel {
      display: flex;
      align-items: center;
      .leads-search-box {
        margin-top: -5rem;
        max-width: 50rem;
        margin-right: 2rem;
      }
      .filter-title {
        color: var(--color-primary-82);
        font-size: 2rem;
        line-height: 2.4rem;
        margin-right: 0.5rem;
      }
      .filter-icon {
        color: var(--color--secondary-205);
      }
    }
  }
}
.hot-indicator {
  color: var(--color--hot-indicator);
}
.warm-indicator {
  color: var(--color--warm-indicator);
}
.room-indicator {
  color: var(--color--room-indicator);
}
.cold-indicator {
  color: var(--color--cold-indicator);
}
.line-connector {
  width: 4.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-top: 0.2rem solid var(--color-primary-94);
  margin-bottom: 0.8rem;
}
.vertical-line-divider {
  border-right: 0.2rem solid var(--color-primary-94);
}
