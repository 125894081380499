.avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
@media (max-width: 900px) {
  .avatar {
    width: 4rem;
    height: 4rem;
  }
}
