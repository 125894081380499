.moreMenuContainer {
  display: flex;
  justify-content: center;
  max-width: 5rem;
  position: relative;
}
.moreMenuIconContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  max-height: 6.6rem;
}
.moreMenuIcon {
  color: var(--color-primary-56);
  font-size: 3.7rem;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
}
.MenuContainer {
  background-color: var(--color-primary-100);
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  box-shadow: 0px 0px 15px hsla(0, 0%, 0%, 0.1); /*shadow color not generic*/
  position: absolute;
  width: 31.1rem;
  z-index: var(--zindex-dropdown);
}
.individualMenuItem {
  align-items: center;
  display: flex;
  height: 5.1rem;
  justify-content: space-between;
  padding: 1.62rem 2.08rem 1.5rem 2.08rem;
  transition: background-color linear 0.2s;
  width: 100%;
}
.individualMenuItem:hover {
  background-color: var(--color--secondary-205);
}
.individualMenuItem:hover .moreIndividualMenuIcon {
  color: var(--color-primary-100);
}
.individualMenuItem:hover .moreIndividualMenuLabel {
  color: var(--color-primary-100);
}
.moreIndividualMenuIcon {
  font-size: 2rem;
  line-height: 1.8rem;
}
.moreIndividualMenuLabel {
  font-size: 1.5rem;
  margin: 0;
}
.countLabel {
  color: var(--color-primary-82);
}
.moreMenuIconContainer:focus,
.moreMenuIconContainer:active {
  -webkit-tap-highlight-color: transparent;
}
/*Classes for menu position*/
.left {
  right: 5rem;
}
.right {
  left: 5rem;
}

/*Helper classes*/
.disabled {
  color: var(--color-primary-70);
  pointer-events: none;
}

@media (max-width: 900px) {
  .moreMenuIcon {
    font-size: 2.5rem;
    margin-bottom: 0;
  }
  .moreMenuIconContainer {
    margin-top: 0;
  }

  .left {
    right: 1rem;
  }
  .right {
    left: 1rem;
  }
  .MenuContainer {
    width: max-content;
    border-radius: 0.6rem;
    top: 2rem;
  }
  .individualMenuItem {
    padding: 1.8rem 1.5rem;
    height: 3rem;
  }
  .moreIndividualMenuLabel {
    font-size: 1.3rem;
    margin-right: 2rem;
  }
  .moreIndividualMenuIcon {
    font-size: 1.3rem;
  }
}
