.approveBtnContainer {
  width: 18.5rem;
}
.approveBtn {
  align-items: center;
  display: flex;
  justify-content: center;
}
.checkIcon {
  font-size: 2.2rem;
  line-height: 1.8rem;
  margin-right: 1.4rem;
}
