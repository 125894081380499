.booked-unit-summary__header {
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--color-primary-70);
  padding-top: 6.2rem;
}

.booked-unit-summary {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) var(--footer-height);
  height: 100%;
}

.booked-unit-summary__body {
  --layout-width: 40%;
  display: flex;
  padding-bottom: 2rem;
  padding-top: 3rem;
}

.booked-unit-summary__details {
  width: calc(100% - var(--layout-width));
  padding-right: 2rem;
}

.booked-unit-summary__layout {
  width: var(--layout-width);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  row-gap: 2.4rem;
  flex-direction: column;
  padding-left: 1.5rem;
}

.booked-unit-summary__layout-photo img {
  border: solid 1rem var(--color-primary-98);
  border-radius: 1rem;
}

.booked-unit-summary__layout-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 3.3rem;
}

.booked-unit-summary__layout-details-item-lable {
  font-size: 1.8rem;
  color: var(--color-primary-70);
  line-height: 2.2rem;
}
.booked-unit-summary__layout-details-item-value {
  font-size: 2.2rem;
  font-weight: bold;
  color: var(--color-primary-56);
  line-height: 2.7rem;
}

.booked-unit-summary-tab-container {
  display: grid;
  grid-template-rows: 6rem minmax(0, 1fr);
  height: 100%;
  row-gap: 3rem;
}

.booked-unit-summary-tab-container__header {
  display: flex;
  font-size: 1.8rem;
  color: var(--color-primary-56);
  line-height: 2.2rem;
  margin-bottom: 3.55rem;
  border-bottom: var(--primary-border);
  height: 100%;
}

.booked-unit-summary-tab-container__header-item {
  width: 18.5rem;
  padding: 1.65rem;
  cursor: pointer;
  text-align: center;
}

.booked-unit-summary-tab-container__header-item.active {
  font-weight: bold;
  border-bottom: 0.4rem solid var(--color-primary-41);
}

.booked-unit-summary-tab-container__body {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 2rem;
}
