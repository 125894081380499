.lightboxWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  transition: all 0.4s ease;
  z-index: var(--zindex-modal);
}
.lightboxImg {
  border-radius: 1rem;
  box-shadow: 0.3rem 0.5rem 0.7rem rgba(0, 0, 0, 0.5);
  display: block;
  margin: auto;
  width: 100%;
  max-width: 90%;
  max-height: 80vh;
}
.bigImage {
  position: relative;
  width: 100%;
}
.closeIconWrap {
  border-radius: 100%;
  position: absolute;
  right: 3%;
  top: -2.3rem;
}
.closeIcon {
  background-color: var(--color-primary-100);
  border-radius: 100%;
  color: var(--color-cp-primary-31);
  font-size: 1.5rem;
  padding: 0.6rem;
}
.previousIcon,
.nextIcon {
  background-color: var(--color-primary-100);
  border-radius: 100%;
  box-shadow: 0.3rem 0.5rem 0.7rem rgba(0, 0, 0, 0.5);
  color: var(--color-cp-primary-31);
  font-size: 1.5rem;
  padding: 0.6rem;
}
.arrowLeft {
  left: 0.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.arrowRight {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-50%);
}
