p {
  margin-bottom: 0;
}

.cardContainer {
  padding-bottom: 4rem;
  padding-top: 7rem;
}
.cardContainer.qrcodeActive {
  padding-top: 3rem;
}

.cardWrapper {
  align-items: center;
  background-color: var(--color-primary-100);
  border-radius: 1rem;
  border: 1px solid var(--color-cp-primary-96);
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1.5rem;
  position: relative;
  padding: 0.8rem;
}

.imageConatiner img {
  border-radius: 0.6rem;
  display: block;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}

.imageWrapOuter {
  opacity: 1;
}

.textWrapper {
  margin-left: 1.4rem;
}

.propertyName {
  color: var(--color-cp-primary-17);
  flex-basis: 70%;
  font-size: 1.3rem;
  font-family: 'SF-UI-bold', sans-serif;
  font-weight: bold;
}

.propertyPrice {
  color: var(--color-cp-primary-61);
  font-size: 1.3rem;
  font-family: 'SF-UI-bold', sans-serif;
  font-weight: bold;
}

.propertyLocation {
  color: var(--color-cp-primary-43);
  font-size: 1rem;
  margin-bottom: 0.6rem;
  font-family: 'SF-UI-regular', sans-serif;
}

.propertySpecs li {
  list-style: disc;
  margin: 0 1.3rem;
  position: relative;
  font-family: 'SF-UI-regular', sans-serif;
}

.propertySpecs {
  color: var(--color-cp-primary-43);
  display: flex;
  font-size: 1rem;
  padding-bottom: 0.6rem;
}

.checkboxWrap {
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
}
