.dropDownInput {
  display: flex;
  min-height: 7.58rem;
  flex-wrap: wrap;
  position: relative;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: hsla(205, 20%, 32%, 1); /*not generic*/
  width: 100%;
  margin: 0 auto;
  margin-top: 2.4rem;
}
.dropDownInput .disabled {
  color: var(--color-primary-56);
  background-color: var(--color-primary-98);
  pointer-events: none;
}
.inputValueContainer {
  background-color: var(--color-primary-100);
  border: 0.1rem solid var(--color-primary-82);
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.inputValueContainer .disabled {
  background-color: var(--color-primary-98);
}
.inputValueContainer[data-app="RELATA-CR"] {
  border: 1px solid hsla(0, 0%, 84%, 1);
  border-radius: 0.5rem;
}
.inputValue {
  padding: 1.51rem 1.67rem 1.51rem 2.08rem;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
}
.iconContainer {
  margin-right: 1.67rem;
}
.iconContainer span {
  font-size: 2.2rem;
}
.iconUp span {
  transform: rotate(-180deg);
}
.options {
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 2rem;
  position: absolute;
  top: 5.8rem;
  border: 0.1rem solid var(--color-primary-82);
  background-color: var(--color-primary-100);
  z-index: 99;
}
.dropDownInput .options {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.options li {
  list-style-type: none;
}

.options button {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-primary-100);
  font-size: 1.6rem;
  padding: 1.5rem 2rem 1.5rem 2rem;
  border: 0;
  height: 5.1rem;
  width: 100%;
  text-align: left;
  font-size: 1.5rem;
  color: hsla(205, 20%, 32%, 1); /*not generic*/
  transition: all 0.3s ease;
}
.correctTickicon {
  max-height: 1rem;
}
.correctTickicon span {
  font-size: 2rem;
}
.options button:hover {
  background-color: hsla(205, 20%, 32%, 1) /* TODO: Color is not generic */;
  color: var(--color-primary-100);
}
.lable {
  font-size: 1rem;
  color: var(--color-primary-82);
  margin-left: 2rem;
  max-height: 1.9rem;
}

.lable[data-app='RELATA-CR'] {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  color: var(--color-cp-primary-43);
  margin: 0;
}

.crApp .inputFieldError {
  /* color is not generic  */
  color: hsla(0, 87%, 61%, 1);
  font-size: 1.1rem;
  font-family: 'SF-UI-regular', sans-serif;
}
.inputFieldError {
  color: var(--color-status-error);
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 0.3rem;
  margin-top: 0.4rem;
  max-height: 1.7rem;
  position: absolute;
  right: 0.5rem;
  text-align: right;
  bottom: -1.5rem;
}
