.container {
  position: relative;
  padding: 2rem 0 8rem 0;
  margin-bottom: 6rem;
}
.headerIcon {
  color: var(--color-cp-primary-31);
  font-size: 2rem;
}
.iconWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1.8rem;
  padding-left: 1.6rem;
}
.subTitle {
  color: var(--color-cp-primary-43);
  display: flex;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.4rem;
}
.headerWrap {
  padding: 2rem 1.8rem;
}
.subTitle li {
  font-size: 1.4rem;
  list-style: disc;
  margin-left: 2.8rem;
}

.headTitle {
  font-size: 1.8rem;
}
.subHeading {
  color: var(--color-cp-primary-31);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
  /* padding: 0 1.8rem; */
}
.textContent {
  padding: 0 1.8rem;
}
.specsWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;
}
.priceValue {
  align-items: flex-end;
  color: var(--color-cp-primary-31);
  display: flex;
  font-family: 'SF-UI-medium', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
}
.featureWrap {
  padding: 2rem 1.8rem;
}
.priceType {
  align-items: flex-start;
  color: var(--color-cp-primary-31);
  flex-basis: 60%;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.4rem;
}
.locationWrap {
  padding: 0 2rem;
  padding-bottom: 2rem;
}
.amenities {
  padding: 0 1.8rem;
  margin-bottom: 2rem;
}
.iframeWrapper {
  margin-top: 1.5rem;
}
.locationWrap img {
  width: 100%;
  display: block;
  border-radius: 0.5rem;
}
.animieWrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-left: 1.4rem;
}
.listAmenity {
  list-style: disc;
  color: var(--color-cp-primary-43);
  font-size: 1.2rem;
  flex-basis: 33.3%;
  margin-bottom: 1rem;
}
.nearByWrap {
  padding-top: 2.2rem;
  padding: 0 1.8rem;
}
::-webkit-scrollbar {
  display: none;
}
.fixedButton {
  bottom: 0px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  left: 0px;
  padding: 1rem 1.8rem;
  position: fixed;
  right: 0px;
  width: auto;
  z-index: var(--zindex-dropdown);
}
.callBtn {
  background-color: var(--color-cp-primary-97);
  border-radius: 2.3rem;
  border: 1px solid var(--color-cp-primary-44);
  color: var(--color-cp-primary-17);
  font-size: 1.3rem;
  height: 4rem;
  line-height: 1.4rem;
}
.register {
  background-color: var(--color-cp-primary-17);
  border-radius: 2.3rem;
  border: 1px solid var(--color-cp-primary-17);
  color: var(--color-cp-primary-97);
  font-size: 1.4rem;
  height: 4rem;
  line-height: 1.4rem;
}

.showCollateralsBtnContainer {
  padding: 0 1.8rem;
}
/* TODO: Make generic full width button */
.showCollateralsBtn {
  margin-top: 4.6rem;
}
