.users-photos-list-card {
  display: flex;
}

.users-photos-list-card__image-container {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 3.8rem;
  height: 3.8rem;
  position: relative;
}

.users-photos-list-card__image-container:not(:first-child) {
  margin-left: -2rem;
}

.users-photos-list-card__image-container:not(:last-child) {
  -webkit-mask: radial-gradient(
    circle 2rem at 3.6rem 50%,
    transparent 99%,
    #fff 100%
  );
  mask: radial-gradient(circle 2rem at 3.6rem 50%, transparent 99%, #fff 100%);
}

.users-photos-list-card__image {
  width: 100%;
  display: block;
  object-fit: cover;
}

.users-photos-list-card__show-more {
  opacity: 20%;
}

.more-tiles-count {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: var(--color--secondary-205);
}
