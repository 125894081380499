.ag-theme-alpine {
  font-family: Roboto, Montserrat, Arial, Helvetica, sans-serif;
  .ag-root-wrapper {
    border-color: var(--color-primary-82);
    overflow: visible !important;
    // !important is being used to force override the agGrid styling, project status dropdown in superAdminDashboard is not properly working if we don't add this.
  }
}
.ag-cell {
  overflow: visible;
}

.ag-root {
  overflow: visible !important;
  // !important is being used to force override the agGrid styling, project status dropdown in superAdminDashboard is not properly working if we don't add this.
  .ag-body-viewport {
    overflow: visible;
  }
  .ag-body-viewport {
    overflow: visible;
  }
  .ag-center-cols-clipper {
    overflow: visible;
  }
  .ag-center-cols-viewport {
    overflow: visible;
  }
}
.ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
  z-index: 0;
}
.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {
  z-index: 999;
}
.ag-theme-alpine .ag-row {
  border-color: var(--color-primary-82);
  background-color: var(--color-primary-100);
}
.ag-theme-alpine .ag-header {
  background-color: var(--color-primary-98);
}

.ag-header-cell-label {
  color: var(--color-primary-57);
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
}

.ag-theme-alpine .ag-header-row {
  color: var(--color-primary-56);
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
  height: 10rem;
}

.ag-theme-alpine .ag-row {
  color: var(--color-primary-57);
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
  height: 12rem;
}

.ag-theme-alpine .ag-cell-inline-editing {
  height: 12rem;
  box-shadow: none;
  background-color: white;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ag-grid-card .ag-react-container {
  margin-top: 1.5rem;
}
.ag-react-container {
  p {
    margin: 0;
  }
}

.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: initial;
  outline: initial;
}

.count-change-column-renderer {
  display: flex;
  gap: 1rem;
}

.project-overview-all-projects-table .count-value {
  color: hsla(0, 0%, 57%, 1);
  font-size: 2.2rem;
  font-weight: 700;
}

.count-change {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 2rem;
}
.count-change .icon {
  font-size: 1.8rem;
}
.ag-paging-button {
  position: static;
}

// Customisations

.relata-ag-grid-header-small-font {
  .ag-header-cell-text {
    font-size: 1.2rem;
  }
}

// Increase specificity with more hirarchey to prefer over other declaration
.ag-row .ag-cell.relata-ag-grid-cell-justify-center {
  justify-content: center;
}

.relata-ag-grid-header-justify-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.relata-ag-grid-cell-wrap .ag-react-container {
  overflow: hidden;
}

.relata-ag-grid-cell-wrap .relata-ag-grid-wrappable-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
