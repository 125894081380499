.profileWrapper {
  padding: 2rem;
  height: 90vh;
  overflow: scroll;
  position: relative;
}
p {
  margin-bottom: 0;
}

.mainWrap {
  display: flex;
  align-items: center;
  flex-flow: column;
  padding-top: 5.5rem;
}
.userType {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.5rem;
  color: var(--color-cp-primary-43);
}
.profileWrap {
  position: relative;
  max-width: 10rem;
  margin-bottom: 1.4rem;
}
.profileWrap > div {
  opacity: 1;
}
.profileWrap img {
  width: 100%;
  display: block;
  border-radius: 100%;
}
.photoIcon {
  font-size: 1.4rem;
  color: var(--color-primary-100);
  padding: 1rem;
}
.cameraIcon {
  position: absolute;
  border-radius: 100%;
  right: -1rem;
  bottom: -1rem;
  border: 0.2rem solid var(--color-primary-100);
  background-color: var(--color-cp-saffron-75);
}
.nameWrap {
  text-align: center;
}

.inputContainer.input {
  margin-top: 1rem;
}
.input .inputBox {
  /* TODO: Color iS not generic */
  border: 1px solid hsla(0, 0%, 84%, 1);
}
