.toast-container {
  bottom: 5.4rem;
  left: 5.4rem;
  position: fixed;
  z-index: var(--zindex-popover);
}

.toast {
  align-items: center;
  background: var(--color-primary-100);
  border-radius: 1rem;
  /*TODO: Shadow color not generic*/
  box-shadow: 0px 0px 15px hsla(0deg, 0%, 0%, 0.116);
  display: flex;
  gap: 2.4rem;
  margin-top: 2rem;
  padding: 2rem;
  padding-right: 7rem;
  position: relative;
  border: 0.1rem solid var(--color-primary-82);
}

.toast-body {
  color: var(--color-primary-56);
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  max-width: 25rem;
}

.toast-body__primary-message {
  color: var(--color--secondary-205);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  word-wrap: break-word;
}

.toast-body__secondary-message {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;
}

.toast .icon {
  color: var(--color-accent);
  font-size: 6rem;
}

.toast .icon-cross {
  color: var(--color-primary-56);
  font-size: 2rem;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}

.toast .icon--success {
  color: var(--color-status-success);
}

.toast .icon--error {
  color: var(--color-status-error);
}

.toast .icon--warning {
  color: var(--color-status-warning-low);
}
@media (max-width:1024px){
  .toast-container {
    top: 5rem;
    bottom: unset;
    left: 45%;
  }
}

@media (max-width: 600px) {
  .toast-container {
    top: 5rem;
    bottom: unset;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .toast-body {
    max-width: 80vw;
    gap: unset;
  }
  .toast-body__primary-message {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .toast-body__secondary-message {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .toast {
    gap: 0rem;
    padding: 1rem 1.5rem;
  }
  .toast .icon {
    font-size: 3rem;
  }
  .toast .icon-cross {
    font-size: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .icon-container{
    height:4rem;
  }
}
