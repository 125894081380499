.cpAssignedLeadHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}
.headerDisclaimer {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  width: 43.2rem;
}
.verticalLineDivider {
  border-right: 0.2rem solid var(--color-primary-94);
}
.indicatorsContainer {
  align-items: center;
  display: flex;
  gap: 1rem;
}
.indicatorTitle {
  color: var(--color-primary-70);
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
}
.indicatorConnectingLine {
  border-top: 0.2rem solid var(--color-primary-94);
  margin-bottom: 0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 4.4rem;
}
.hotIndicatorIcon {
  color: var(--color--hot-indicator);
}
.warmIndicatorIcon {
  color: var(--color--warm-indicator);
}
.roomTempIndicatorIcon {
  color: var(--color--room-indicator);
}
.coldIndicatorIcon {
  color: var(--color--cold-indicator);
}
.indicatorTitle {
  color: var(--color-primary-70);
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: center;
}
.filterContainer {
  align-items: center;
  display: flex;
}
.searchBox {
  margin-right: 2rem;
  margin-top: -5rem;
  max-width: 50rem;
}
.filterTitle {
  color: var(--color-primary-82);
  font-size: 2rem;
  line-height: 2.4rem;
  margin-right: 0.5rem;
}
.filterIcon {
  color: var(--color--secondary-205);
}
