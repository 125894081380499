.discoveryWrap {
  padding: 2rem 1.8rem;
}
p {
  margin-bottom: 0;
}
.searchContainer {
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid var(--color-cp-primary-96);
  display: flex;
  display: flex;
  flex-basis: 85%;
  flex-flow: row wrap;
  padding: 1rem;
  position: relative;
}
.SettingIcon {
  border-radius: 0.5rem;
  border: 0.5px solid var(--color-cp-primary-96);
}

.tuneIcon {
  color: var(--color-primary-56);
  font-size: 1.2rem;
  padding: 1rem;
}
.searchWrap {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 1.8rem;
}
input[type='search'] {
  background: transparent;
  border-radius: inherit;
  border: none;
  border: none;
  color: var(--color-cp-primary-17);
  flex-basis: 90%;
  font-size: 1.2rem;
  margin-left: 0.6rem;
  position: relative;
}
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='search']::placeholder {
  color: var(--color-primary-56);
  font-size: 1rem;
}
.searchIcon {
  color: var(--color-primary-56);
  font-size: 1.2rem;
}
.propertyHighLight {
  position: relative;
  margin: 1.8rem 0 1.5rem 0;
}
.closeIconWrap {
  position: absolute;
  right: 1.5rem;
  top: 0.2rem;
}
.closeIcon {
  color: var(--color-cp-primary-98);
  font-size: 1.4rem;
}
.overlayBg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  border-radius: 0.5rem;
  /* Gredient colors */
  background-image: linear-gradient(
    to right,
    #796dcc,
    #6773d1,
    #5179d4,
    #377ed6,
    #0083d6
  );
}
.contentWrap {
  color: var(--color-cp-primary-98);
  display: flex;
  flex-flow: column;
  align-items: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  width: 80%;
}
.propertyLocation {
  color: inherit;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.1rem;
}

.imageWrap img {
  display: block;
  width: 100%;
  border-radius: 0.5rem;
}
.exploreButton {
  background-color: var(--color-cp-primary-97);
  border-radius: 1.8rem;
  color: var(--color-cp-primary-17);
  font-size: 1.3rem;
  height: 3.6rem;
  max-width: 15rem;
  padding: 1rem 3.1rem;
  text-transform: uppercase;
}
.propertyName {
  font-size: 2rem;
  font-family: 'SF-UI-semibold', sans-serif;
  color: inherit;
  font-weight: 500;
}
.propertyDealers {
  color: inherit;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.propertySpecs {
  color: inherit;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.1rem;
  margin-bottom: 1.7rem;
}

.tabItem {
  color: var(--color-cp-primary-17);
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-right: 4rem;
  font-weight: bold;
  font-family: 'SF-UI-semibold', sans-serif;
  transition: all 0.4s ease;
}

.tabItem:last-child {
  margin-right: 0;
}

.tabs {
  display: flex;
  flex-flow: row wrap;
  padding: 2rem 0;
  justify-content: space-between;
}

.tabItem[data-tab-active='false'] {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-weight: unset;
}
