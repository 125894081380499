.iconWithLabelContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.icon {
  font-size: 3.7rem;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
  transition: transform ease 0.3s;
}
.iconWithLabelContainer:hover .icon {
  transform: scale(1.04) translateY(-0.3rem);
}
.iconWithLabelContainer:active .icon {
  transform: scale(0.9);
}
.label {
  color: var(--color-primary-82);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0;
}

/*Helper Classes*/
.disabled {
  pointer-events: none;
}
