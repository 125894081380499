.gridContainer {
  margin-top: 3rem;
}
.projectStatusContainer {
  border-radius: 0.3rem;
  height: 5.3rem;
  text-align: center;
  width: 20.9rem;
}
.projectStatusText {
  display: inline;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.executiveCellText {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-weight: 900;
}
.projectDisabled {
  color: var(--color-primary-70);
  transform: scale(0.9);
}

/*Project Status Colors*/
.ongoingContainer {
  background-color: hsla(
    94,
    50%,
    95%,
    1
  ); /*TODO: Background color not generic*/
}
.ongoingText {
  color: var(--color-status-success);
}

.onboardingContainer {
  background-color: hsla(
    44,
    100%,
    93%,
    1
  ); /*TODO: Background color not generic*/
}
.onboardingText {
  color: var(--color--hot-indicator);
}

.renewalContainer {
  background-color: hsla(
    11,
    100%,
    94%,
    1
  ); /*TODO: Background color not generic*/
}
.renewalText {
  color: var(--color-status-danger);
}

.blockedContainer {
  background-color: var(--color-status-error);
}
.blockedText {
  color: var(--color-primary-100);
}

.expiredContainer {
  background-color: var(--color-primary-94);
}
.expiredText {
  color: var(--color-primary-56);
}
