/*Send Invite Modal*/
.modalAdditionalClasses {
  padding: 7.2rem 6.6rem 6.7rem 6.6rem;
  width: 48.3rem;
}

/*Send Invite Modal Header*/
.modalTitleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.modalTitle {
  /*TODO: Font is not generic (used poppins insted of roboto in design)*/
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0;
}
.crossIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.modalDesc {
  /*TODO: Color not generic*/
  color: hsla(0, 0%, 44%, 1);
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-block: 1.45rem 2.35rem;
  opacity: 0.65;
}

/*Send Invite Modal Body*/
.meetingScheduleDatePickerContainer {
  display: flex;
  position: relative;
}
.meetingScheduleDatePickerLabel {
  color: var(--color-primary-82);
  font-size: 1rem;
  line-height: 1.3rem;
  margin-left: 2rem;
  margin-right: 4.5rem;
}
.dateTimePickerInput {
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  color: var(--color--secondary-205);
  font-size: 1.5rem;
  height: 5.1rem;
  margin-bottom: 2.4rem;
  padding: 1rem;
  width: 100%;
}
.dateTimePickerInputsLabelsContainer {
  display: flex;
  justify-content: space-between;
}
.messageBoldText {
  font-weight: 700;
}
.sendInviteModalMessageContainer {
  margin-top: 2.4rem;
}
.sendInviteModalMessage {
  background-color: var(--color-primary-98);
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  min-width: 3.11rem;
  word-break: break-all;
  padding: 1.78rem 3.11rem 1.81rem 2.8rem;
}
.sendInviteModalMessage p {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 1.8rem;
}

/*send Invite Modal Footer*/
.sendInviteModalFooter {
  margin-top: 4.5rem;
}
.copyToClipBoardButton {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
}

.copyToClipboardBtn {
  width: 100%;
  height: 5.3rem;
  padding-block: 1.7rem;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 500;
  cursor: pointer;
}
.copyToClipboardBtn p {
  color: var(--color-primary-70);
}
.copyIcon {
  font-size: 2.2rem;
  line-height: 1.8rem;
  color: var(--color-primary-70);
  margin-right: 1.1rem;
}

.ScheduleDemoInstructionLabel {
  text-align: right;
  margin-right: 0;
  margin-top: 0.4rem;
}
.warningMessageDurationUnderDurationDropdwon {
  color: var(--color-yellow);
  text-align: right;
  margin-right: 0;
  margin-top: 0.4rem;
}

/*Helper Classes*/
.hideElement {
  height: 0;
  opacity: 0;
}
