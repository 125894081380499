.performance-executive-card {
  display: inline-flex;
  flex-direction: row;
  width: 31.6rem;
  gap: 1.7rem;
  box-sizing: border-box;
  padding-left: 2.2rem;
  cursor: pointer;
}

.performance-executive-card:hover {
  background-color: var(--color-primary-95);
}

.performance-executive-card.selected {
  padding-left: 1rem;
  border-left: 1.2rem solid var(--color--secondary-205);
  background-color: var(--color-primary-98);
}

.performance-executive-card__image-container {
  position: relative;
}

.performance-executive-card__image {
  display: block;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  object-fit: cover;
  opacity: 1;
  margin-top: 1.6rem;
}

.performance-executive-card__image-container .selected-tick {
  position: absolute;
  top: 2.5rem;
  left: 1.2rem;
}

.selected-tick {
  color: var(--color-primary-98);
  width: 2.4rem;
  height: 2.5rem;
}

.performance-executive-card__content {
  margin-top: 2.3rem;
}
.performance-executive-card__role {
  font-size: 1.2rem;
  line-height: 1.44rem;
  color: var(--color-primary-19);
  opacity: 0.6;
}

.performance-executive-card__name {
  font-size: 2rem;
  line-height: 3.12rem;
  color: var(--color--secondary-205);
  font-weight: 700;
}

.performance-executive-card__value {
  font-size: 2.5rem;
  line-height: 3.3rem;
  color: var(--color-primary-57);
}

.performance-executive-card__arrow {
  font-size: 2.2rem;
  line-height: 2.6rem;
}

.positive-growth {
  color: var(--color--growth-positive);
}

.negative-growth {
  color: var(--color--growth-negative);
}
