.headerProjectName {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 4rem;
  font-weight: 600;
  line-height: 6rem;
  margin: 0;
}
.projectNameAndDropDownArrowContainer {
  align-items: center;
  display: flex;
}
.headerDeveloperName {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.boldDeveloperName {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.dropDownArrowIcon {
  color: var(--color--secondary-205);
  font-size: 4.7rem;
  line-height: 1.8rem;
  margin-left: 1.5rem;
}
.analyticsContainer {
  height: 100%;
  margin-top: 4.7rem;
  width: 100%;
}
