/*Body*/
.organizationColumnCell {
  margin-top: 2rem;
}

.displayName {
  color: var(--color-primary-41);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin-bottom: 0.4rem;
}
.cityName {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 0;
}
.noOfProjects {
  font-weight: 800;
}
