.container {
  height: 90vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.contentWrap {
  display: block;
  left: 0;
  padding: 0 2rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 60%;
  transform: translateY(-50%);
}
.title {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-bold', sans-serif;
  font-size: 2.8rem;
  font-weight: bold;
  margin-top: 4rem;
  padding-bottom: 2.1rem;
}

.subTitle {
  color: var(--color-cp-primary-31);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  margin-bottom: 3.9rem;
}
.getStartedBtn {
  background-color: var(--color-cp-primary-17);
  border-radius: 1rem;
  color: var(--color-cp-primary-97);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
}
.welcomeImge {
  display: block;
  margin: 0 auto;
  max-width: 26rem;
  width: 100%;
}
