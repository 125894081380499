.adminDashboardHeader {
  display: flex;
  justify-content: space-between;
}
.headerLeftContainer {
  flex: 2;
}
.headerHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 4rem;
  font-weight: 600;
  line-height: 6rem;
  margin: 0;
}
.headerDes {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-top: 0.4rem;
}
.boldHeaderDes {
  font-weight: 700;
}
.searchBoxContainer {
  margin-top: -2.2rem;
  width: 38.9rem;
}
.dashboardHeaderButtonsContainer {
  display: flex;
  flex: 1;
  min-width: 25.8rem;
}
.createAdminButton {
  height: 5.3rem;
  min-width: 11.7rem;
}
.createAdminButton:first-child {
  margin-right: 4rem;
}
