.leadContainer {
  padding: 2rem 1.8rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.filterWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nameWrapper {
  margin: 2.5rem 0;
}
.inputFeild {
  font-family: 'SF-UI-regular', sans-serif;
  color: var(--color-cp-primary-17);
  font-size: 1.3rem;
  line-height: unset;
  border-radius: 0.5rem;
  border: 0.5px solid var(--color-cp-primary-83);
  padding: 1.4rem 1rem;
}
input {
  width: 100%;
  font-family: 'SF-UI-regular', sans-serif;
  color: var(--color-cp-primary-17);
  font-size: 1.3rem;
  line-height: unset;
}
.digitWrap {
  border-radius: 0.5rem;
  border: 0.5px solid var(--color-cp-primary-83);
  padding: 1.3rem 0.8rem;
  width: 100%;
  display: inline-flex;
  margin-bottom: 2.5rem;
}
.digitWrap input {
  border: none;
  text-decoration: none;
  max-width: 1.5rem;
  text-align: center;
}
.digitWrap input::placeholder {
  color: var(--color-cp-primary-44);
  font-size: 1.3rem;
}
.subHeading {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  color: var(--color-cp-primary-43);
  margin-bottom: 0.7rem;
}
.propertyConfigs {
  border: 0.5px solid var(--color-cp-primary-83);
  border-radius: 0.6rem;
  font-size: 1.3rem;
  font-family: 'SF-UI-regular', sans-serif;
  color: var(--color-primary-56);
  transition: all 0.4s ease;
  text-align: center;
  padding: 0.7rem 1.6rem;
  margin-bottom: 1.1rem;
  min-width: 8.1rem;
  margin-left: 1rem;
}
.selectedItem {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-primary-100);
  border: 0.5px solid var(--color-cp-primary-83);
  border-radius: 0.5rem;
  padding: 1rem 1.2rem;
  margin-right: 1rem;
  margin-top: 1rem;
  transition: all 0.4s ease;
}
.selectedItem.active {
  background-color: var(--color-cp-primary-91);
  color: var(--color-cp-primary-35);
  border: 1px solid var(--color-cp-primary-91);
}
.selectedItem.active .item,
.selectedItem.active .filterClose {
  color: var(--color-cp-primary-35);
}
.item {
  color: var(--color-primary-56);
  font-size: 1.3rem;
  margin-right: 0.8rem;
}
.filterClose {
  color: var(--color-primary-56);
  font-size: 1rem;
}

.propertyConfigs:nth-child(4n),
.propertyConfigs:first-child {
  margin-left: 0;
}
.searchIcon {
  color: var(--color-primary-56);
  font-size: 1.3rem;
}
.searchContainer {
  align-items: end;
  border-radius: 0.5rem;
  border: 0.5px solid var(--color-cp-primary-83);
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  position: relative;
}
.closeIcon {
  font-size: 2rem;
  color: var(--color-cp-primary-17);
}

.propertyConfigs.active {
  background-color: var(--color-cp-primary-91);
  color: var(--color-cp-primary-35);
  border: 1px solid var(--color-cp-primary-91);
}
.btnAddLead {
  color: var(--color-cp-primary-97);
  font-family: 'SF-UI-semibold', sans-serif;
  background-color: var(--color-cp-primary-17);
  border-radius: 1rem;
  font-weight: bold;
}
.leadBtnWrap {
  display: block;
  margin: 4rem 0 6rem 0;
}
.dropdownWrap {
  margin-top: 2rem;
}

.configurationWrap {
  margin-top: 2rem;
}
