.unitDetailsModal {
  padding: 6rem 11.8rem 5.1rem 7.9rem;
  width: 165.8rem;
}

/*Header*/
.headingAndIconContainer {
  display: flex;
  justify-content: space-between;
}
.modalHeadingContainer {
  align-items: center;
  display: flex;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
  margin: 0;
  margin-right: 1rem;
}
.reraNumber {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0;
}
.rearNumberBoldFont {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-weight: 700;
}
.closeIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.headerDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  margin-top: 1.85rem;
  opacity: 0.65;
}

/*Body*/
.modalBody {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 4.175rem;
}
.unitImageContainer {
  align-items: center;
  background-color: red;
  border-radius: 0.5rem;
  border: 0.1rem solid hsla(0, 0%, 86%, 1); /*TODO:Border Color not generic*/
  display: flex;
  height: 72rem;
  justify-content: center;
  margin-right: 6.1rem;
  width: 46rem;
}
.allDetailsContainer {
  flex: 3;
}
.unitDetailsContainer {
  display: flex;
  flex-wrap: wrap;
}
.detailsNameHeading {
  color: var(--color--secondary-205);
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.7rem;
  margin: 0;
  margin-bottom: 2.825rem;
}
.individualDetailContainer {
  margin-bottom: 3.925rem;
  width: 18.5rem;
}
.detailsLabel {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0;
}
.detailsValue {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  margin-top: 1.5rem;
}
.costBreakUpDetailsAndHeadingContainer {
  border-top: 0.1rem solid hsla(0, 0%, 86%, 1); /*TODO: Color not generic*/
  padding-top: 3.925rem;
}
.additionalChargesDetailsAndHeadingContainer {
  border-top: 0.1rem solid hsla(0, 0%, 86%, 1); /*TODO: Color not generic*/
  margin-bottom: 0;
  padding-top: 3.925rem;
}

.costBreakUpDetailsContainer {
  display: flex;
  flex-wrap: wrap;
}
.additionalChargesDetailsContainer {
  margin-bottom: 3.925rem;
  width: 27.5rem;
}
