.site-visit {
  display: flex;
  height: 100%;
  border-radius: 1rem;
  border: 0.1rem solid var(--color-primary-41);
  background: var(--color-primary-100);
  min-height: 76.2rem;
}

.site-visit-desc {
  background-image: url(https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/login-background.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 1rem 0 0 1rem;
  color: var(--color-primary-100);
  display: flex;
  flex-basis: 62%;
  flex-direction: column;
  gap: 1.1rem;
  justify-content: flex-end;
  padding: 8rem;
}

.site-visit-desc__title {
  font-weight: bold;
  font-size: 4rem;
  line-height: 4.8rem;
  max-width: 60%;
}

.site-visit-desc__desc {
  font-size: 2rem;
  line-height: 2.6rem;
  opacity: 0.65;
  max-width: 60%;
}

.site-visit-form {
  flex-basis: 38%;
  padding: 8.6rem;
  font-size: 1.8rem;
}

.site-visit-form__header {
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--color-primary-70);
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.site-visit-form--valid .site-visit-from__header-title {
  color: var(--color-accent);
}

.site-visit-form__header .icon-cross {
  position: absolute;
  right: 0;
  font-size: 2rem;
  top: 0;
}

.site-visit-form__header .icon-edit {
  position: absolute;
  left: 0;
  font-size: 2rem;
}

.site-visit-form__body {
  margin-top: 1.8rem;
}

.site-visit-form .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  position: relative;
  width: 100%;
}

.site-visit-form .input--otp {
  margin-top: 4.15rem;
}

.site-visit-form .input-date {
  display: flex;
  gap: 1.2rem;
  justify-content: space-between;
}

.site-visit-form .input_label {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--color-primary-82);
  margin-bottom: 0.25rem;
  padding-left: 2rem;
}

.site-visit-form .input_field {
  color: var(--color-primary-56);
  background: var(--color-primary-100);
  border: 0.1rem solid var(--color-primary-82);
  border-radius: 0.2rem;
  padding: 1.6rem 2rem;
  height: 5.1rem;
}

.site-visit-form .input__error {
  position: absolute;
  top: 6.5rem;
  color: red;
  padding-left: 2rem;
}

.site-visit-form .input__resend-otp {
  position: absolute;
  bottom: -1.5rem;
  right: 0;
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--color-accent);
}

.site-visit-form__footer {
  margin-top: 4.8rem;
}

.site-visit-form__footer .input_field {
  height: 5.3rem;
  background: var(--color-primary-82);
  color: var(--color-primary-100);
}

.site-visit-form__footer .input_field.click-enabled {
  background: var(--color-accent);
}

.site-visit-form--summery-with-otp .site-visit-form__body {
  margin-top: 3.58rem;
}

.form-summery-desc {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--color-primary-56);
  text-align: center;
}
.form-summery__item {
  width: 100%;
  color: var(--color-primary-56);
  margin-bottom: 1rem;
  display: flex;
}
.form-summery__item-label {
  width: 24%;
  display: inline-block;
}
.form-summery__item-value {
  width: 76%;
  display: inline-block;
  font-weight: bold;
}
.form-summery {
  margin-top: 3.81rem;
  padding-bottom: 4.05rem;
  border-bottom: var(--primary-border);
}
.site-visit-form--summery .form-summery {
  border-bottom: inherit;
}

.site-visit-form--summery-with-otp .site-visit-from__header-title,
.site-visit-form--summery .site-visit-from__header-title {
  color: var(--color-accent);
}

.site-visit-form--summery .site-visit-form__header {
  display: flex;
  flex-direction: column;
}

.site-visit-form--summery .icon-container--correct-tick {
  color: var(--color-accent);
  border-color: var(--color-accent);
  height: 8rem;
  width: 8rem;
  font-size: 4rem;
  margin-top: 2.3rem;
}

.site-visit-form--summery .icon-correct-tick {
  font-size: 4rem;
}

.form-summery__heading {
  border-bottom: var(--primary-border);
  margin-top: 2.2rem;
  margin-bottom: 3rem;
  padding-bottom: 0.9rem;
}
