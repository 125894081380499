.payment-page {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) var(--footer-height);
  overflow: hidden;
}

.payment-page__body {
  display: flex;
  height: 100%;
  width: 100%;
}

.payment-page-status-image {
  width: 65%;
  height: 100%;
  object-fit: cover;
}
.payment-image-container {
  background: #fff;
  width: 100%;
  position: relative;
}
