.containerWrap {
  padding: 2rem 1.8rem;
}

.headerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.2rem;
}
.searchWrapper {
  margin-bottom: 2rem;
}
.closeIcon {
  font-size: 2rem;
  color: var(--color-cp-primary-17);
}
.filterWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}
.propertyConfigs {
  border: 0.5px solid var(--color-cp-primary-83);
  border-radius: 0.3rem;
  font-size: 1.3rem;
  font-family: 'SF-UI-regular', sans-serif;
  color: var(--color-cp-primary-17);
  transition: all 0.4s ease;
  text-align: center;
  padding: 0.7rem 1.6rem;
  margin-bottom: 1.1rem;
  min-width: 8.1rem;
  margin-left: 1rem;
}
.propertyConfigs:nth-child(4n),
.propertyConfigs:first-child {
  margin-left: 0;
}

.propertyConfigs.active {
  background-color: var(--color-cp-primary-91);
  color: var(--color-cp-primary-35);
  border: 1px solid var(--color-cp-primary-91);
}
.filtersSubText {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  color: var(--color-cp-primary-31);
  margin-bottom: 1rem;
}
input[type='search'] {
  background: transparent;
  border-radius: inherit;
  border: none;
  border: none;
  color: var(--color-cp-primary-56);
  flex-basis: 90%;
  font-size: 1.3rem;
  margin-left: 0.6rem;
  position: relative;
}
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='search']::placeholder {
  color: var(--color-primary-56);
  font-size: 1.3rem;
}
.searchIcon {
  color: var(--color-primary-56);
  font-size: 1.3rem;
}
.searchContainer {
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid var(--color-primary-56);
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  position: relative;
}
.selectedItem {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-cp-primary-91);
  border: 0.5px solid var(--color-cp-primary-83);
  border-radius: 0.5rem;
  padding: 1rem 1.2rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
.item {
  color: var(--color-cp-primary-35);
  font-size: 1.3rem;
  margin-right: 0.8rem;
}
.filterClose {
  color: var(--color-cp-primary-35);
  font-size: 1rem;
}
.dropDownWrap,
.priceWrap {
  margin-bottom: 2.5rem;
}
.btnAddLead {
  color: var(--color-cp-primary-97);
  font-family: 'SF-UI-semibold', sans-serif;
  background-color: var(--color-cp-primary-17);
  border-radius: 1rem;
  font-weight: bold;
  margin-top: 2rem;
}
