.table {
  position: relative;
}

.table__search {
  position: absolute;
  right: 5rem;
  top: -8rem;
}

.table__search-box {
  display: flex;
  align-items: center;
  color: var(--color-primary-82);
  border: var(--primary-border);
  border-radius: 0.3rem;
  padding-left: 1.8rem;
}

.search-box input {
  font-size: 1.8rem;
  padding: 1.4rem;
  border: 0;
}

.table__table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.8rem;
  color: var(--color-primary-56);
}

.table__table thead {
  text-align: left;
  background: var(--color-primary-98);
  border-collapse: collapse;
}

.table__table td,
th {
  border-bottom: var(--primary-border);
  padding: 3.6rem;
}
.table__editatble-cell {
  /* cursor: default;
  pointer-events: inherit; */
}
.table__editatble-cell input {
  width: 10rem;
  cursor: auto;
}

.table__editatble-cell .btn {
  padding: 1rem 2rem;
  margin-left: 1rem;
  border-radius: 1rem;
  /* cursor: pointer; */
}
