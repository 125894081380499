.loginFormHeading {
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0 0 1.9rem 0;
}
.loginFormDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO:color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-bottom: 1.9rem;
}

.loginButton {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  margin-top: 4.6rem;
}
.loginButton.crLogin{
  background-color: var(--color-cp-primary-17);
}