.footerIcon {
  color: var(--color-cp-primary-17);
  font-size: 2rem;
  opacity: 0.6;
  transition: all 0.4s ease;
}
p.footerIcon {
  font-size: 1rem;
  opacity: 0.7;
  transition: all 0.4s ease;
}
.footerIcon.active {
  opacity: 1;
}

.p.footerIcon.active {
  opacity: 1;
}
