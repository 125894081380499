.content-header--payment-plan .content-header__title {
  display: flex;
  gap: 1rem;
}

.content-header--payment-plan .content-header__drop-down {
  position: relative;
  font-size: 2.5rem;
  line-height: 2rem;
}

.content-header--payment-plan .dropdown-list {
  font-size: 1.5rem;
}

.content-header--payment-plan .dropdown-list__item,
.content-header--payment-plan .dropdown__default-text {
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: bold;
  white-space: nowrap;
}

.content-header--payment-plan .icon {
  font-size: 3rem;
}

.content-header--payment-plan .icon:before {
  content: '\00a0 ';
}

.content-header--payment-plan .dropdown {
  padding-bottom: 0;
}
