.loading-overlay {
  display: none;
}

.loading-overlay--enabled {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  z-index: var(--zindex-modal);
}

/* Button loading */
.loading-spin-container {
  opacity: 0.8;
  position: relative;
  cursor: default;
  pointer-events: none;
}

.loading-spin-container .ic2-fa-spin-blue {
  display: block;
}

.ic2-fa-spin-blue {
  --radius: 2rem;
  border: 0.4rem solid var(--color-primary-82);
  border-radius: 100%;
  border-top: 0.4rem solid var(--color-primary-56);
  width: var(--radius);
  height: var(--radius);
  display: none;
  position: absolute;
  top: calc(50% - (var(--radius) / 2));
  left: 0.9rem;
  animation: ic2-spin 2s linear infinite;
}

@keyframes ic2-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn--pdf-download .ic2-fa-spin-blue {
  --radius: 3rem;
  left: inherit;
  top: inherit;
}
