.moreActionsMenuIconContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  max-height: 6.6rem;
}
.moreActionsMenuIcon {
  color: var(--color-primary-56);
  font-size: 3.7rem;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
}
.actionsMenuContainer {
  background-color: var(--color-primary-100);
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  box-shadow: 0px 0px 15px hsla(0, 0%, 0%, 0.1); /*shadow color not generic*/
  left: -29rem;
  position: absolute;
  width: 31.1rem;
}
.individualMenuItem {
  align-items: center;
  display: flex;
  height: 5.1rem;
  justify-content: space-between;
  padding: 1.62rem 2.08rem 1.5rem 2.08rem;
  width: 100%;
}
.moreActionsIndividualMenuIcon {
  font-size: 2rem;
  line-height: 1.8rem;
}
.moreActionsIndividualMenuLabel {
  font-size: 1.5rem;
  margin: 0;
}
.countLabel {
  color: var(--color-primary-82);
}

/*Helper classes*/
.blockColor {
  color: var(--color-status-error);
}
