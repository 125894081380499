.tooltipContainer {
  position: relative;
  width: max-content;
}

.tooltip {
  /*TODO: Shadow color not generic*/
  background-color: var(--color-primary-100);
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  box-shadow: 0 0 1.5rem hsla(0, 0%, 0%, 0.1);
  font-size: 1.4rem;
  left: calc(100% + 1rem);
  padding: 0.5rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
