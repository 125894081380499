.addCompany {
  padding: 7.2rem 6.7rem 7rem 6.8rem;
  width: 87.5rem;
}

/*Header*/
.headerIconAndHeadingContainer {
  display: flex;
  justify-content: space-between;
}
.closeIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
  margin: 0;
  margin-bottom: 1.85rem;
}
.modalDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  margin-bottom: 2.35rem;
  opacity: 0.65;
  width: 60.4rem;
}

/*Body*/
.sideBySideTextInputsContainer {
  display: flex;
  justify-content: space-between;
}
.smallTextInputContainer {
  width: 31.1rem;
}
.sideBySideTextInputsContainer:nth-of-type(3) {
  border-bottom: 0.1rem solid var(--color-primary-82);
  padding-bottom: 4.2rem;
}

/*Footer*/
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 4.2rem;
}
.createOrganizationBtn {
  width: 31.2rem;
}
