.btnsContainer {
  display: flex;
}
.approveBtnContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.approveBtn {
  background-color: var(--color-status-success);
  border-radius: 50%;
  border: 0;
  height: 5.3rem;
  margin-right: 3.4rem;
  width: 5.3rem;
}
.rejectBtn {
  background-color: var(--color-primary-56);
  margin-right: 3.4rem;
}
.approveIcon {
  color: var(--color-primary-100);
  font-size: 2.2rem;
  line-height: 1.8rem;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}
