.modalAdditionalClasses {
  width: 87.5rem;
  padding: 7.2rem 6.6rem 8.6rem 6.8rem;
}

/*Modal Header*/
.modalHeader {
  display: flex;
  justify-content: space-between;
}
.modalHeaderLeftContent {
  width: 60.4rem;
}
.modalHeaderLeftContent h1 {
  font-family: Poppins, Roboto, sans-serif;
  font-weight: 800;
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 3.8rem;
  margin: 0;
  margin-bottom: 1.85rem;
}
.modalHeaderLeftContent p {
  color: hsla(0, 0%, 44%, 1); /*TODO: color ot generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-top: 1.85rem;
  margin: 0;
  opacity: 0.65;
}
.modalCloseIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}

/*Modal Body*/
.projectIdInputContainer {
  display: flex;
  justify-content: space-between;
}
.projectIdInput {
  width: 31.1rem;
}

/*Modal Footer*/
.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 6.4rem;
}
.footerDisclaimer {
  font-size: 1rem;
  /*TODO: Color not generic*/
  color: hsla(0, 0%, 44%, 1);
  line-height: 1.3rem;
}
.verifyNowBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
}
/*Helper Classes*/
.disableBtn {
  background-color: var(--color-primary-70);
}
