.heading {
  display: flex;
  flex-direction: column;
}

.heading__title {
  color: var(--color-accent);
  font-size: 1.8rem;
  font-weight: bold;
}

.heading__desc {
  color: var(--color-primary-56);
  font-size: 1.2rem;
}
