/*Header*/
.header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 3.3rem;
}
.searchBoxContainer {
  margin-right: 3rem;
  margin-top: -5.9rem;
  width: 38.9rem;
}
.filterContainer {
  align-items: center;
  display: flex;
}
.filterText {
  color: var(--color-primary-82);
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  margin-right: 1rem;
}
.filterIcon {
  color: var(--color--secondary-205);
  font-size: 3.2rem;
  line-height: 3.8rem;
}

/*Body*/
.threeGridsContainer {
  display: flex;
}
.gridContainer {
  margin: 3.4rem 3.6rem 6rem 0;
}
.gridContainer:last-of-type {
  margin-right: 0;
}
.configText {
  color: var(--color-primary-56);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin: 0;
}
