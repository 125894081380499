/*Header*/
.projectSelection {
  color: var(--color--secondary-205);
  width: 100%;
}

.projectSelection main {
  overflow-y: scroll;
  max-height: 50vh;
  padding: 1rem;
}
.iconsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.apartmentIconContainer {
  align-items: center; /*TODO: backgroundColor not generic*/
  background-color: hsla(205, 20%, 32%, 0.06);
  border-radius: 50%;
  display: flex;
  height: 4.9rem;
  justify-content: center;
  padding: 1rem;
  width: 4.9rem;
}
.apartmentIcon {
  font-size: 2.8rem;
  line-height: 1.6rem;
}
.fullscreenIconContainer {
  align-items: center;
  display: flex;
}
.fullscreenIcon {
  font-size: 3.6rem;
  line-height: 2rem;
  margin-right: 1.5rem;
}
.logoutIcon {
  font-size: 2.8rem;
  line-height: 1.6rem;
}
.headerTitle {
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
}
.headerDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-bottom: 3.65rem;
  opacity: 0.65;
}

/*Main*/
.labelContainer {
  align-items: center;
  display: flex;
}
.radioBtnTextContainer {
  margin-left: 2.7rem;
}
.projectNameInRadioBtn {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
  margin-bottom: 0.2rem;
}
.developerNameInRadioBtn {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  opacity: 0.65;
}

/*Footer*/
.footer {
  text-align: right;
}
.nextButton {
  border-radius: 1rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-block: 1.5rem 2rem;
  width: 17.2rem;
}

/*Project Selector radio button*/
.projectSelectorRadioBtnContainer input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.projectSelectorRadioBtnContainer label {
  align-items: center;
  border-radius: 1.2rem;
  border: 0.1rem solid var(--color-primary-82);
  cursor: pointer;
  display: flex;
  height: 9.9rem;
  margin-bottom: 1.8rem;
  padding: 1.9rem 1.8rem;
  /* width: 60.7rem; */
}
.projectSelectorRadioBtnContainer input[type='radio']:checked + label {
  border-color: var(--color--secondary-205);
  border-width: 0.3rem;
}
.projectSelectorRadioBtnContainer input[type='radio']:focus + label {
  border-color: var(--color--secondary-205);
  border-width: 0.3rem;
}

@media (max-width: 900px) {
  .apartmentIconContainer {
    height: 3.2rem;
    width: 3.2rem;
  }
  .apartmentIcon {
    font-size: 2.2rem;
  }
  .iconsContainer {
    align-items: baseline;
  }
  .headerTitle {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 0.7rem;
  }
  .headerDesc {
    font-size: 1.3rem;
    line-height: 2.2rem;
    max-width: 85%;
    margin-bottom: 1.5rem;
  }
  .projectSelection main {
    max-height: 75vh;
    padding: 0;
  }
  .projectSelectorRadioBtnContainer label {
    height: auto;
    padding: 1.3rem 1.2rem;
  }
  .projectNameInRadioBtn {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .developerNameInRadioBtn {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }
}
