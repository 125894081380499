.LandscapeFlashScreen {
  display: none;
}
.FlashScreen-Message {
  text-align: center;
}
@media only screen and (orientation: portrait) {
  .LandscapeFlashScreen {
    /* display: block; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 10rem;
  }
  .app-main {
    display: none;
  }

  /* body {
    height: 100vw;
    width: 100vw;
    transform: rotate(90deg);
  } */
}
@media only screen and (orientation: landscape) {
  .app-main {
    display: block;
  }

  .LandscapeFlashScreen {
    display: none;
  }
}

@media(max-width:1024px){
 .LandscapeFlashScreen{
    padding: 2rem;
    font-size: 3rem;
  }
}
