.adminLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17.6rem 17rem 20.1rem 12.6rem;
  background-color: #8c7479; /*Remove*/
  height: 100vh;
}
.adminLoginLeftContainer {
  flex: 1;
}
.relataLogo {
  color: var(--color-primary-100);
  font-family: PerfectlyAmicable, sans-serif;
  font-size: 8.5rem;
  line-height: 8.6rem;
  margin: 0;
  margin-bottom: 2.9rem;
  opacity: 0.53;
}
.adminLoginLeftContainer h2 {
  color: var(--color-primary-100);
  font-family: 800;
  font-family: Poppins, sans-serif;
  font-size: 6.9rem;
  line-height: 7.9rem;
  margin: 0;
}
.adminLoginLeftContainer ul {
  margin-top: 2.5rem;
  margin-left: 2rem;
}
.adminLoginLeftContainer li {
  /* list-style: circle; */
  color: var(--color-primary-100);
  font-size: 2.6rem;
  line-height: 3.2rem;
  margin-bottom: 3.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.bulletCircle {
  width: 2.3rem;
  height: 2.3rem;
  border: 0.4rem solid var(--color-primary-100);
  border-radius: 50%;
  display: inline-block;
  margin-right: 3.2rem;
}

.adminLoginRightContainer {
  flex: 1;
  max-width: 44.3rem;
  height: 70.3rem; /*Remove*/
  padding: 7.8rem 6.5rem 4rem 6.6rem;
  background-color: var(--color-primary-100);
  border-radius: 1rem;
}
.loginFormHeading {
  /* TODO: Font not generic as per theme */
  font-size: 2.8rem;
  line-height: 3.8rem;
  color: var(--color--secondary-205);
  margin: 0 0 1.9rem 0;
}
.loginFormDesc {
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: hsla(0, 0%, 44%, 1); /*TODO:color not generic*/
  margin-bottom: 1.9rem;
}

@media (max-width: 900px) {
  .adminLoginLeftContainer {
    display: none;
  }
  .adminLogin {
    padding: 1.5rem;
  }
}
