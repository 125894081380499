.project-overview-container__header {
  display: flex;
  justify-content: space-between;
}
.project-overview-container__title {
  font-size: 4rem;
  color: var(--color--secondary-205);
  line-height: 4.9rem;
  font-family: Montserrat;
  font-weight: 700;
}

.project-overview-container__duration {
  display: flex;
  gap: 1rem;
  padding-right: 1rem;
}

.project-overview-container__add .btn-add {
  width: 22.8rem;
  height: 5.1rem;
  background-color: var(--color--secondary-205);
  border-radius: 2px;
  color: hsla(0, 0%, 100%, 1);
  font-size: 1.5rem;
  line-height: 1.8rem;
}

.project-overview-container__duration {
  display: flex;
}

.project-overview-container__duration .duration-tile {
  padding: 1.4rem;
  border: hsla(0, 0%, 83%, 1) 1px solid;
  height: 5.1rem;
  width: 10.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  color: hsla(0, 0%, 57%, 1);
  cursor: pointer;
}

.project-overview-container__duration .duration-tile.active {
  background-color: hsla(0, 0%, 98%, 1);
}

.project-overview-container__duration :first-child {
  border-radius: 3px 0 0 3px;
}
.project-overview-container__duration :last-child {
  border-radius: 0 3px 3px 0;
}
