.modalAdditionalClasses {
  padding: 7.2rem 6.7rem 5.2rem 6.8rem;
  width: 87.5rem;
}

/*Modal Header*/
.modalHeader {
  display: flex;
  justify-content: space-between;
}

.modalHeaderLeftContent {
  width: 60.4rem;
}

.modalHeaderLeftContent h1 {
  color: var(--color--secondary-205);
  font-family: Poppins, Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 3.8rem;
  margin: 0;
}

.modalHeaderLeftContent p {
  color: hsla(0, 0%, 44%, 1);
  /*TODO: color ot generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  margin-top: 1.85rem;
  opacity: 0.65;
}

.modalCloseIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}

/*Modal Body*/
.bodyIndividualdRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inputsContainer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 31.1rem;
}

.inputsContainer p {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-top: 0.4rem;
  text-align: right;
}


.formDividerLineContainer {
  background-color: var(--color-primary-82);
  margin-block: 4.2rem 1.8rem;
  padding: 0.1rem;
}

/*Modal Footer*/
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 4.7rem;
}

.footerDisclaimer {
  color: hsla(0, 0%, 44%, 1);
  /*TODO:color not generic*/
  font-size: 1rem;
  line-height: 1.3rem;
  margin: 0;
  width: 31.2rem;
}

.createProjectBtnContainer {
  min-width: 31.2rem;
}

.createProjectBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
}