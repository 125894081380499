/*Header*/
.iconsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.pinDropIconContainer {
  align-items: center;
  background-color: hsla(
    0.06 20%,
    205,
    32%
  ); /*TODO: BackgroundColor not generic*/
  border-radius: 50%;
  color: var(--color--secondary-205);
  display: flex;
  height: 4.9rem;
  justify-content: center;
  width: 4.9rem;
}
.pinIcon {
  font-size: 3rem;
}
.headerTitle {
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
  margin-top: 2rem;
}
.headerDesc {
  color: hsla(0, 0%, 44%, 0.6); /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-bottom: 0;
}
.boldText {
  font-weight: bold;
  color: var(--color--secondary-205);
  font-size: 1.7rem;
}

/*Main*/
.formWrap {
  min-width: 40rem;
}
.textField {
  width: 56.5rem;
}
.otpMessage {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-bottom: -3rem;
  margin-top: 5.1rem;
}
.boldcustomerMobile {
  font-weight: 600;
}

/*Footer*/
.footer {
  display: flex;
  justify-content: space-between;
  margin-block: 5rem 0rem;
}
.sendOtpButton {
  width: 17.2rem;
}

.otpWrapper {
  margin-top: 3rem;
}
/* for ipad */
@media (max-width: 1024px) {
  .pinDropIconContainer {
    width: 3rem;
    height: 3rem;
  }
  .pinIcon {
    font-size: 2rem;
  }
  .headerTitle {
    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: 1rem;
  }
  .textField {
    width: 100%;
  }
  .sendOtpButton {
    font-size: 1.2rem;
    flex-basis: 45%;
    line-height: 1.5rem;
    padding-block: unset;
    width: 100%;
    height: 4rem;
  }
}
/* for mobile */
@media (max-width: 600px) {
  .formWrap {
    width: 100%;
    min-width: unset;
  }
}
