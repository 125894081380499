.pageWrap {
  padding: 2rem 1.8rem;
  display: flex;
  flex-flow: column;
}
.backIcon {
  font-size: 2rem;
  color: var(--color-cp-primary-31);
  margin-bottom: 1.4rem;
}
