.brand-card {
  display: inline-flex;
  flex-direction: row;
  gap: 3rem;
}

.brand-card__logo {
  width: 15.5rem;
  height: 15.5rem;
}
.brand-card__logo-image {
  border-radius: 100%;
  object-fit: cover;
}

.brand-card__content {
  width: 56.9rem;
}

.brand-card__project-name {
  font-family: Montserrat;
  font-size: 4rem;
  line-height: 5rem;
  font-weight: 700;
  color: var(--color--secondary-205);
}
.brand-card__group-name-label {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
  color: var(--color-primary-72);
}
.brand-card__group-name-value {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
  color: var(--color--secondary-205);
}
