.addNewCpModal {
  padding: 7.2rem 6.7rem 5.2rem 6.8rem;
  width: 87.5rem;
}
.headingAndCloseIconContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.85rem;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 3.8rem;
  margin: 0;
}
.closeIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.modalDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  opacity: 0.65;
}

/*Body*/
.twoParallelTextInputsContainer {
  display: flex;
  justify-content: space-between;
}
.smallTextBox {
  width: 29.1rem;
}
.addressDivider {
  align-items: center;
  display: flex;
  margin-block: 3.5rem -1.5rem;
}
.permanentAddressHeading {
  color: var(--color--secondary-205);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin: 0;
  margin-right: 1.25rem;
  width: 19%;
}
.dividerLine {
  background-color: var(--color-primary-82);
  height: 0.1rem;
  margin: 0;
  padding: 0.1rem;
  width: 80%;
}

/*Footer*/
.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 8rem;
}
.footerDisclaimer {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1rem;
  line-height: 1.3rem;
  width: 31.2rem;
}
.addCpBtnContainer {
  width: 31.2rem;
}
