.modalAdditionalClasses {
  padding: 7.2rem 6.6rem 7.1rem 6.8rem;
  width: 125rem;
}

/*Modal Header*/
.modalHeader {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-family: Poppins, Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  font-weight: 800;
  line-height: 3.8rem;
  margin: 0 0 1.85rem 0;
}
.modalDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0 0 4.15rem 0;
  opacity: 0.65;
  width: 60.4rem;
}

/*Modal Body*/
.gridContainer {
  box-shadow: 0px 0px 20px hsla(205, 20%, 32%, 0.08); /*TODO: Shadow Color not generic*/
  margin-bottom: 3.8rem;
  max-height: 63.4rem;
  overflow: scroll;
}
.actionIcon {
  margin-top: 2rem;
}

/*TODO:Make this class generic*/
.disabledIcon {
  color: var(--color-primary-82);
}

/*Modal Footer*/
.footerContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.footerLeftContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.addExecutiveButton {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  margin-right: 4.1rem;
  width: 31.2rem;
}
.disclaimer {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic*/
  font-size: 1rem;
  line-height: 1.3rem;
  max-width: 31.2rem;
}
.executiveCount {
  color: var(--color-primary-56);
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0;
}
