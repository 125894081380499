.tabItem {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 4rem;
  transition: all 0.4s ease;
}

.tabItem:last-child {
  margin-right: 0;
}

.tabs {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 2rem 0;
}

.tabItem[data-tab-active='false'] {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-weight: unset;
}

.contentWrapper {
  height: 75vh;
  width: 100%;
  overflow: auto;
  padding-bottom: 2rem;
}
