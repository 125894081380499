.cardWrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 2rem;
}
.headTitle {
  font-size: 1.4rem;
  font-family: 'SF-UI-medium', sans-serif;
  color: var(--color-cp-primary-31);
}
.earnValues {
  display: flex;
  flex-flow: column;
  flex-basis: 75%;
}
.earningsWrap {
  display: flex;
  align-items: center;
}
.value {
  font-family: 'Roboto', sans-serif;
  font-size: 3.3rem;
  color: var(--color-cp-primary-17);
  font-weight: bold;
  line-height: 3.3rem;
  margin-top: 0.6rem;
}
.subText strong {
  color: var(--color-cp-primary-31);
  font-family: 'SF-UI-bold', sans-serif;
  font-size: 1rem;
}
.subText {
  color: var(--color-cp-primary-43);
  font-size: 1rem;
}
.progressWrap {
  width: 75px;
}
.valuesBold {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
