input[type='checkbox'] {
  clip-path: inset(50%);
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  background: var(--color-primary-100);
  border-radius: 0.7rem;
  border: 1px solid var(--color-cp-border-primary);
  box-shadow: 0 0 0 0.1rem var(--color-cp-border-primary);
  display: inline-block;
  height: 2.4rem;
  margin-right: 4px;
  padding: 2px;
  transition: all 0.4s ease;
  width: 2.4rem;
}

.checkboxActive {
  background: var(--color-cp-primary-31);
  border-color: var(--color-cp-primary-31);
  transition: all 0.4s ease;
}
