.siteVisitForm {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 4rem 11.3rem 3.3rem 11.4rem;
  width: 83.4rem;
  background: #fff;
}
.fullscreenContainer {
  background: #fff;
}
/* for iPad screen */
@media (max-width: 1024px) {
  .siteVisitForm {
    padding: 2rem;
    width: 50rem;
  }
}
/* for mobile screen */
@media (max-width: 600px) {
  .siteVisitForm {
    width: 100%;
    padding: 2.5rem 1.8rem;
  }
}
