.image-container {
  height: 100%;
  width: 100%;
}

.image-container__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.loading-spin-container {
  position: relative;
}

.loading-spin {
  border: 0.4rem solid #008ad6;
  border-radius: 100%;
  border-top: 0.4rem solid #f3f3f3;
  width: 3rem;
  height: 3rem;
  display: block;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  animation: loading-spin 2s linear infinite;
}

.loading-spin--hide {
  display: none;
}

@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
