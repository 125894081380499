.modalAdditionalClasses {
  padding: 5rem;
  max-width: 80rem;
}

/*header*/
.header {
  display: flex;
  justify-content: space-between;
}
.modalHeading {
  font-family: Poppins, Roboto, sans-serif;
  font-weight: 800;
  margin: 0;
  margin-bottom: 1.84rem;
  font-size: 2.8rem;
  line-height: 3.8rem;
  color: var(--color--secondary-205);
}
.modalDesc {
  color: hsla(0, 0%, 44%, 1) /*TODO: Color not generic*/;
  font-size: 1.5rem;
  line-height: 2.4rem;
  opacity: 0.65;
}

/*body*/
.modalBody {
  display: flex;
  margin-top: 3rem;
}
.confirmButton {
  margin-right: 2rem;
  color: var(--color-primary-100);
}

/*Confirm Button Background color Helper classes*/
.danger {
  background-color: var(--color-status-danger);
}
.success {
  background-color: var(--color-status-success);
}
.warn {
  background-color: var(--color-status-warning-low);
}
