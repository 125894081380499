.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.cursor-disable {
  cursor: not-allowed;
}
.disabled-icon {
  color: var(--color-primary-82);
  pointer-events: none;
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}

.onTextHoverAnimation {
  transition: transform 0.4s ease;
}
.onTextHoverAnimation:hover {
  transform: scale(0.95);
}
.onTextHoverAnimation:active {
  transform: scale(1.02);
}
