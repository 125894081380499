.tabsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
}
.tabContainerHeader {
  border-bottom: var(--primary-border);
  color: var(--color-primary-56);
  display: flex;
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.tabItem {
  cursor: pointer;
  padding-bottom: 1.75rem;
  text-align: center;
  width: 18.5rem;
}
.activeTab {
  border-bottom: 0.4rem solid var(--color-primary-41);
  font-weight: bold;
}
.disabledTab {
  color: var(--color-primary-82);
  pointer-events: none;
}
.tabContainerBody {
  overflow: hidden;
  padding-right: 2rem;
}
