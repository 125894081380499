/*Toggle Button*/
.switch {
  display: inline-block;
  height: 3rem;
  margin-top: 2rem;
  position: relative;
  width: 6rem;
}
.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}
.slider {
  background-color: var(--color-primary-82);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.slider:before {
  background-color: var(--color-primary-100);
  bottom: 0.4rem;
  content: '';
  height: 2.2rem;
  left: 0.4rem;
  position: absolute;
  transition: 0.4s;
  width: 2.2rem;
}
input:checked + .slider {
  background-color: var(--color-status-success);
}
input:focus + .slider {
  box-shadow: 0 0 0.1rem var(--color-status-success);
}
input:checked + .slider:before {
  transform: translateX(2.9rem);
}

/* For Rounded sliders */
.roundEdges {
  border-radius: 3.4rem;
}

.roundEdges:before {
  border-radius: 50%;
}
