.pageWrap {
  padding: 2rem 1.8rem;
  position: relative;
}
.titleWrap {
  background-color: var(--color-primary-100);
  display: flex;
  flex-flow: column;
  left: 0;
  padding: 2rem 1.8rem 1rem 1.8rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-1);
}
.titleWrap.activeQrModel {
  position: relative;
  padding: 0;
}
button:focus,
button:active {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
button {
  background-color: unset;
  border: none;
  padding: 0;
  text-decoration: none;
}
.btnWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.doneBtn {
  color: var(--color-cp-primary-61);
  font-family: 'SF-UI-medium', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}
.locationDetails {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.4rem;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* QR Code  */

.qrCodeWrap {
  align-items: center;
  background-color: var(--color-cp-primary-17);
  display: flex;
  height: 70vh;
  justify-content: center;
  padding: 0 1.8rem;
  position: relative;
}
.headerButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  color: var(--color-primary-100);
  left: 0;
  right: 0;
  top: 2.5rem;
  padding: 0 1.8rem;
}
.qrContainer {
  background-color: var(--color-primary-100);
  padding: 2rem 0;
  border-radius: 1.2rem;
}
.topConatiner {
  /* color is not gneric */
  padding: 0 1.8rem;
  border-bottom: 1px dashed hsla(0, 5%, 79%, 1);
}
.detailsWrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.leftConatiner {
  flex-basis: 55%;
  display: flex;
  flex-flow: column;
}
.leadDetails {
  display: flex;
  flex-flow: column;
}
.leadType {
  font-family: 'SF-UI-regualr', sans-serif;
  font-size: 1.3rem;
  color: var(--color-cp-primary-43);
  margin-bottom: 0.3rem;
}
.leadValue {
  color: var(--color-cp-primary-17);
  font-size: 1.3rem;
  font-family: 'SF-UI-bold', sans-serif;
  font-weight: bold;
}
.imagWrap {
  flex-basis: 43%;
}
.spaceLine {
  margin-top: 1.8rem;
}
.bottomConatiner {
  padding: 0 1.8rem;
}
.headerBtn {
  color: inherit;
  font-family: 'SF-UI-regualr', sans-serif;
  font-size: 1.3rem;
}
.shareIcon {
  color: inherit;
  font-size: 2rem;
  -webkit-tap-highlight-color: transparent;
}
