.modalAdditionalClasses {
  padding: 7.2rem 6.7rem 5.2rem 6.8rem;
  width: 87.5rem;
}

/*Modal Header*/
.modalHeader {
  display: flex;
  justify-content: space-between;
}
.modalHeaderLeftContent {
  width: 60.4rem;
}
.modalHeaderLeftContent h1 {
  color: var(--color--secondary-205);
  font-family: Poppins, Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 3.8rem;
  margin: 0;
}
.modalHeaderLeftContent p {
  color: hsla(0, 0%, 44%, 1); /*TODO: color ot generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  margin-top: 1.85rem;
  opacity: 0.65;
}
.modalCloseIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}

/*Modal Body*/
.bodyIndividualdRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.inputsContainer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 31.1rem;
}
.inputsContainer p {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-top: 0.4rem;
  text-align: right;
}
.inputSuggestion {
  color: var(--color-primary-82);
}
.addDeveloperTextInputContainer {
  min-height: 7.58rem;
  width: 100%;
}
.addDeveloperButton {
  background-color: var(--color-primary-100);
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  color: var(--color-primary-41);
  font-size: 2.2rem;
  max-height: 5.4rem;
  margin-left: 0.8rem;
  padding: 1.38rem 1.6rem 1.5rem 1.6rem;
  width: 5.4rem;
}
.addNewDeveloperIcon {
  font-size: 2.2rem;
  line-height: 1.8rem;
  transition: 0.5s all ease;
}
.formDividerLineContainer {
  background-color: var(--color-primary-82);
  margin-block: 4.2rem 1.8rem;
  padding: 0.1rem;
}
.contactDetailsInputContainer {
  display: block;
}
.developerDropDownContainer {
  margin-top: 2.4rem;
}
.developerInputContainer {
  margin-top: 0.6rem;
}

/*Modal Footer*/
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 4.7rem;
}
.footerDisclaimer {
  color: hsla(0, 0%, 44%, 1); /*TODO:color not generic*/
  font-size: 1rem;
  line-height: 1.3rem;
  margin: 0;
  width: 31.2rem;
}
.createProjectBtnContainer {
  min-width: 31.2rem;
}
.createProjectBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
}

/*Update Project Modal*/
.statusAndIsBlockedDropdownsContainer {
  display: flex;
  width: 100%;
}
.dropDownCotainer {
  margin-top: 2.4rem;
}
.meetingScheduleDatePickerContainer {
  display: flex;
  position: relative;
}
.meetingScheduleDatePickerLabel {
  color: var(--color-primary-82);
  font-size: 1rem;
  line-height: 1.3rem;
  margin-left: 2rem;
  margin-right: 4.5rem;
}
.dateTimePickerInput {
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  color: var(--color--secondary-205);
  cursor: pointer;
  font-size: 1.5rem;
  height: 5.1rem;
  padding: 1rem;
}
.dateTimePickerInputsLabelsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/*helper classes*/
.btnDisabled {
  background-color: var(--color-primary-70);
  cursor: not-allowed;
}
.rotateAddDeveloperIcon {
  transform: rotate(45deg);
  transition: 0.5s all ease;
}
