.headerWrap {
  background-color: var(--color-primary-100);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  left: 0;
  padding: 2rem 1.8rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-1);
}
.backIcon {
  color: var(--color-cp-primary-31);
  font-size: 2.2rem;
}
.heading {
  color: var(--color-cp-primary-31);
  font-size: 1.5rem;
  font-family: 'SF-UI-medium', sans-serif;
  font-weight: bold;
}
.saveBtn {
  color: var(--color-cp-primary-61);
  display: flex;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.5rem;
}

.spinnerLeft {
  margin-right: 0.5rem;
  position: initial;
}
