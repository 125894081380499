/* Max Width */

:root {
  /* theme */
  --color-accent: #906fc8;

  /* --color-primary: ; */
  --color-primary-19: hsla(0, 0%, 19%, 1);
  --color-primary-41: hsla(0, 0%, 41%, 1);
  --color-primary-56: hsla(0, 0%, 56%, 1);
  --color-primary-57: hsla(0, 0%, 57%, 1);
  --color-primary-70: hsla(0, 0%, 70%, 1);
  --color-primary-72: hsla(0, 0%, 72%, 1);
  --color-primary-82: hsla(0, 0%, 82%, 1);
  --color-primary-94: hsla(0, 0%, 94%, 1);
  --color-primary-95: hsla(0, 0%, 95%, 1);
  --color-primary-98: hsla(0, 0%, 98%, 1);
  --color-primary-100:hsla(0, 0%, 100%, 1);
  --color-primary-97: hsla(0, 0%, 97%, 1);

  --color-yellow: hsla(40, 100%, 63%, 1);
  --color-black: hsla(0, 0%, 19%, 1);

  /* TODO: Make this color generic */
  /* secondary  */
  --color--secondary-205: hsla(205, 20%, 32%, 1);

  /* Heights */
  --footer-height: 6.95rem;

  /* Status */

  --color-status-success: hsla(88, 90%, 41%, 1);
  --color-status-error: hsla(0, 100%, 42%, 1);
  --color-status-warning-low: hsla(40, 100%, 63%, 1);
  --color-status-warning-high: hsla(0, 100%, 42%, 1);
  --color-status-incomplete: hsla(38, 87%, 62%, 1);
  --color-status-danger: hsla(0, 100%, 42%, 1);

  /* Growth */

  --color--growth-positive: hsla(96, 58%, 79%, 1);
  --color--growth-negative: hsla(0, 58%, 79%, 1);

  /* Indicators */
  --color--hot-indicator: hsla(26, 100%, 59%, 1);
  --color--warm-indicator: hsla(40, 100%, 63%, 1);
  --color--room-indicator: hsla(188, 76%, 64%, 1);
  --color--cold-indicator: hsla(0, 0%, 70%, 1);

  /* Border */
  --primary-border: 0.2rem solid var(--color-primary-94);

  /* Widths */
  --unit-summary-card-width: 32%;

  /* Indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  scrollbar-color: var(--color-primary-70) var(--color-primary-94);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: var(--color-primary-94);
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary-70);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-56);
}

@font-face {
  font-family: PerfectlyAmicable;
  src: url(../fonts/perfectly-amicable.ttf);
}

@font-face {
  font-family: 'SF-UI-bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/sf-font/sf-ui-display-bold-58646a511e3d9.woff)
    format('woff');
}

@font-face {
  font-family: 'SF-UI-semibold', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/sf-font/sf-ui-display-semibold-58646eddcae92.woff)
    format('woff');
}
@font-face {
  font-family: 'SF-UI-medium', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/sf-font/sf-ui-display-medium-58646be638f96.woff)
    format('woff');
}

@font-face {
  font-family: 'SF-UI-regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/sf-font/sf-ui-display-light-58646b33e0551.woff)
    format('woff');
}

li {
  list-style: none;
}

ul,
figure {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

.click-enabled {
  cursor: pointer;
  pointer-events: inherit;
}

.click-disabled {
  cursor: default;
  pointer-events: none;
}

.click-disabled--icon {
  cursor: default;
  pointer-events: none;
  color: var(--color-primary-82);
}

.click-disabled-with-opacity {
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}

.click-disabled--only-pointer-events {
  cursor: inherit;
  pointer-events: none;
}

.hide {
  display: none;
}

/* Typography */
html {
  /* 62.5% of 16px = 10px */
  /* font-size: 62.5%; */
  font-size: calc(10 * 100vw / 1920);
}

// for ipad fonts
@media only screen and (max-width: 1024px) {
  html {
    font-size: calc(10 * 100vw / 600);
  }
}

// Used for CP Webapp for mobile
@media only screen and (max-width: 767px) {
  html {
    /* 62.5% of 16px = 10px */
    /* font-size: 62.5%; */
    font-size: calc(10 * 100vw / 375);
  }
}

body {
  font-family: Roboto, Montserrat, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.5;
  color: var(--color-body);
}

h1,
h2,
h3 {
  color: var(--color-heading);
  font-size: 7rem;
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

p {
  margin-top: 0;
}

@media screen and (min-width: 102.4rem) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

/* Font */
.font-accent-bold {
  color: var(--color-accent);
  font-weight: bold;
}

.f-regular {
  font-weight: 400;
}

.f-medium {
  font-weight: 500;
}

.f-bold {
  font-weight: 700;
}

.f-10 {
  font-size: 1rem;
}

.f-12 {
  font-size: 1.2rem;
}

.f-14 {
  font-size: 1.4rem;
}

.f-15 {
  font-size: 1.5rem;
}

.f-18 {
  font-size: 1.8rem;
}

.f-20 {
  font-size: 2rem;
}

/* Color */
.c-56 {
  color: var(--color-primary-56);
}

.c-accent {
  color: var(--color-accent);
}

/* Links */
a {
  text-decoration: none;
  color: var(--color-primary-56);
}

/* Input */
input:focus,
input:valid {
  outline: 0;
}

/* Hide Input Number Arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Lists */
.list {
  color: var(--color-heading);
  list-style: none;
  padding-left: 0;
}

/* Icons */
.icon {
  font-size: 3rem;
}

.sidebar-item .icon {
  color: var(--color-primary-82);
}

.sidebar-item--active .icon {
  color: var(--color-accent);
}

.sidebar-item--completed .icon {
  color: var(--color-primary-56);
}

.icon-container {
  width: 5.4rem;
  height: 5.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-container--with-background {
  background: var(--color-primary-98);
  border-radius: 100%;
  border: 0.5rem solid var(--color-primary-94);
}

.sidebar-item--active .icon-container {
  border-color: var(--color-accent);
}

.sidebar-item--completed .icon-container {
  border-color: var(--color-primary-94);
  background: #fff;
}

/* Collapsibles */

.collapsible__header {
  display: flex;
  justify-content: space-between;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__chevron {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

/* Navigation Bar */
.nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem;
  align-items: center;
}

.nav__left-container {
  display: flex;
}
.nav__list {
  align-items: center;
  display: flex;
  margin: 0;
  max-height: 100vh;
  opacity: 1;
  width: auto;
}

.nav__item {
  border-bottom: 0.1rem solid #222;
  border: 0;
  color: var(--color-primary-56);
  padding: 0.5rem 2rem;
}

.nav__item--disabled {
  color: var(--color-primary-82);
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.nav__toggler {
  cursor: pointer;
  display: none;
  opacity: 0.5;
  transition: box-shadow 0.15s;
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
  box-shadow: 0 0 0 0.3rem #666;
  border-radius: 0.5rem;
}

.nav__brand__relata-logo {
  font-family: PerfectlyAmicable, sans-serif;
  color: var(--color--secondary-205);
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-right: 3rem;
}
.nav__developer-name {
  color: var(--color-primary-70);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.8rem;
}

.nav__brand img {
  height: 100%;
}

.nav__vbar {
  border: 0.1rem solid var(--color-primary-41);
  height: 3.6rem;
  opacity: 0.16;
  padding: 0;
  width: 0.1rem;
}

/* Buttons */

.btn {
  border-radius: 4rem;
  border: 0;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0;
  padding: 1rem 4rem;
  text-align: center;
  white-space: nowrap;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--outline {
  background: transparent;
  border: 0.3rem solid var(--color-accent);
  color: var(--color-accent);
}

.displaynone {
  display: none;
}

.btn--icon-label-container {
  align-items: center;
  display: flex;
  position: relative;
}

.btn--icon-label-container .icon {
  position: absolute;
  right: 1rem;
}

.btn--transparent {
  background: inherit;
  color: var(--color-primary-56);
}

.btn--wrap {
  margin: 0;
  padding: 0;
}

.btn--enabled {
  background: var(--color-accent);
  color: #fff;
}

.btn--disabled {
  color: var(--color-primary-82);
  pointer-events: none;
}

.btn--outline-disabled {
  border-color: var(--color-primary-82);
  color: var(--color-primary-82);
  cursor: default;
  pointer-events: none;
}

@media screen and (min-width: 102.4rem) {
  .btn {
    font-size: 1.5rem;
  }
}

/* Info Card */
.info-card {
  border-radius: 1rem;
  display: inline-flex;
  flex-direction: column;
  padding: 1.5rem 5rem 1.5rem 1.9rem;
  border: var(--primary-border);
}

.info-card__header {
  color: var(--color-primary-70);
  font-size: 1.2rem;
}

.info-card__body {
  color: var(--color-accent);
  font-size: 2.5rem;
}

/* Details Card */

.details-card {
  align-items: center;
  background: var(--color-primary-100);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 0.5rem;
  width: 9.4rem;
}

.details-card .icon {
  font-size: 2.5rem;
}

.details-card__title {
  color: var(--color-primary-82);
  font-size: 1.2rem;
  margin-bottom: 2.2rem;
}

.details-card__desc {
  color: var(--color-primary-70);
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.5rem;
  text-align: center;
}

/* Summary Card */
.unit-summery-card {
  width: var(--unit-summary-card-width);
  padding-left: 6.4rem;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  overflow: hidden;
}

.unit-summery-card__header {
  display: flex;
  flex-direction: column;
  background: var(--color-primary-98);
  border: var(--primary-border);
  border-radius: 5px;
  text-align: right;
  padding: 2rem 4rem 1rem;
}

.unit-summery-card__total-cost {
  font-size: 3.4rem;
  margin-top: 0;
}

.unit-summery-card__summary {
  margin-top: 1.5rem;
  overflow: auto;
  height: 100%;
  padding-right: 1rem;
}

.unit-summery-card__summary-heading {
  margin-bottom: 2rem;
}

.unit-summery-card__summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

/* Thumb */
.thumb {
  height: 6.5rem;
  width: 6.5rem;
  border: 0.3rem solid var(--color-primary-82);
  border-radius: 100%;
}

.thumb img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

/* Global */
.app-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-main--loading {
  opacity: 0.7;
  filter: blur(2px);
  cursor: default;
  transition-duration: 0.3s;
  pointer-events: none; /* Disable click on modal  */
}

.app-header {
  background: #fff;
  border-bottom: var(--primary-border);
  color: var(--color-primary-56);
  font-family: Roboto;
  font-size: 1.8rem;
  font-weight: 400;
  min-height: 7.7rem;
  height: 8vh;
}

/* Body */
.app-body {
  display: flex;
  height: 92vh;
}

.app-header nav {
  height: 100%;
  margin: 0 auto;
  padding: 0 3rem;
}

/* Sidebar */

.sidebar {
  background: var(--color-primary-98);
  border-right: 0.5rem solid var(--color-primary-94);
  flex-basis: 18%;
}

.sidebar-item {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 2.65rem 4.6rem 2.65rem 0;
  pointer-events: none;
}

.sidebar-item--active {
  color: var(--color-accent);
}

.sidebar-item.sidebar-item--completed {
  pointer-events: inherit;
}

.sidebar-item::after {
  background: var(--color-primary-94);
  border: 0;
  content: '';
  height: 5.153rem;
  position: relative;
  right: 2.95rem;
  top: 5.4rem;
  width: 0.5rem;
}

.sidebar-item--connector-bar-disable::after {
  background: var(--color-primary-94);
  border: 0;
  content: '';
  height: 0.1rem;
  position: relative;
  right: 2.95rem;
  top: 5.4rem;
  width: 0.5rem;
}

.sidebar-status-dot-container {
  position: relative;
}

.sidebar-status-dot-wrapper {
  align-items: center;
  background: var(--color-primary-98);
  border-radius: 100%;
  bottom: -2rem;
  display: inline-flex;
  height: 4.4rem;
  justify-content: center;
  left: 3.25rem;
  position: absolute;
  width: 4.4rem;
}

.sidebar-status-dot {
  background: var(--color-primary-98);
  border-radius: 100%;
  border: 0.6rem solid var(--color-primary-94);
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
}

.sidebar-item--active .sidebar-status-dot {
  background: var(--color-accent);
}

.sidebar-item--completed .sidebar-status-dot {
  background: var(--color-primary-56);
}

.sidebar-label-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: 2.2rem;
  color: var(--color-primary-82);
}

.sidebar-item--completed .sidebar-label-container__label {
  color: var(--color-primary-56);
}

.sidebar-item--completed .sidebar-label-container__desc {
  color: var(--color-primary-70);
}

.sidebar-item--active .sidebar-label-container__label {
  color: var(--color-accent);
}
.sidebar-item--active .sidebar-label-container__desc {
  color: var(--color-primary-70);
}

.sidebar-label-container__label {
  font-size: 1.8rem;
  font-weight: 500;
}

.sidebar-label-container__desc {
  font-size: 1.5rem;
}

/* Content */
.content {
  color: var(--color-primary-70);
  display: flex;
  flex-basis: 82%;
  flex-direction: column;
  padding: 6rem 6rem 6rem 6rem;
}

.content--dashbaord {
  padding-top: 0;
  padding-right: 0;
  overflow: hidden;
}

/* Content Body */
.content-body {
  height: 100%;
  width: 100%;
  padding-top: 4.3rem;
  overflow: auto;
}

.content-body--dashbaord {
  padding-top: 0;
  padding-right: 6rem;
}

/* Content with unit summary card */
.content-with-unit-summary-card {
  display: grid;
  grid-template-rows: minmax(0, 1fr) var(--footer-height);
  height: 100%;
}

.content-with-unit-summary-card__body {
  display: flex;
}

.content-with-unit-summary-card__content {
  width: calc(100% - var(--unit-summary-card-width));
  overflow-y: auto;
}
/* Background Scroll off*/
.no-scroll{
  overflow: hidden;
}


/* Content Header */
.content-header {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.content-header__title-plate {
  display: inline-flex;
  flex-direction: column;
}

.content-header__title {
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: normal;
}

.content-header__description {
  font-size: 1.5rem;
  max-width: 67.4rem;
}

.content-header__card-container {
  display: flex;
  gap: 2.5rem;
}

.content-header__nav {
  margin-left: 2rem;
}

.content-header__nav .btn {
  color: var(--color-primary-56);
  font-weight: normal;
  justify-content: space-between;
  min-width: 14.5rem;
  padding: 1.2rem 1.6rem 1.2rem 2rem;
  text-align: left;
}

.content-header__nav .nav__item {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  padding: 0.5rem 0.5rem;
}

.content-header__nav .nav__item-desc {
  bottom: 0.8rem;
  color: var(--color-primary-70);
  display: block;
  font-size: 1.2rem;
  line-height: 1.4rem;
  position: absolute;
}

.content-header__nav .nav__vbar {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0;
}

.content-header__nav .btn.btn--accent {
  background-color: var(--color-accent);
  color: #fff;
  font-weight: bold;
}

.content-header__nav .btn.btn--enabled {
  background-color: var(--color-primary-94);
  color: var(--color-primary-56);
  font-weight: normal;
}

.content-header__nav .btn.btn--disabled {
  background-color: var(--color-primary-98);
  color: var(--color-primary-82);
  font-weight: normal;
}

.content-header-nav-filter {
  position: relative;
}

.filter-preferences-summery {
  font-size: 1.5rem;
  position: absolute;
  bottom: -1.5rem;
  right: 0.5rem;
  z-index: 1;
}

.filter-preferences-summery__label {
  /* columns: var(--color-primary-70); */
}

.filter-preferences-summery__item {
  display: inline-block;
  font-weight: bold;
  color: var(--color-accent);
  padding: 0 1rem;
  border-right: var(--primary-border);
  cursor: pointer;
}

.filter-preferences-summery__item:last-child {
  border-right: 0;
}

/* ul li:last-child {
  border: 1px solid red;
  color: red;
} */

.filter-preferences-summery__more {
  padding-left: 1rem;
  font-weight: bold;
}

.content-header-nav-filter__clear {
  background: inherit;
  border: 0;
  color: var(--color-primary-70);
  cursor: pointer;
  font-size: 1.2rem;
  min-width: 10rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: -1rem;
}

.content-header__nav-filters-preference-applied {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1.45rem;
  width: 1.45rem;
  border: 0.4rem solid var(--color-primary-100);
  background: var(--color-yellow);
  border-radius: 100%;
}

.content-header-nav-filter .btn {
  background: transparent;
  border: 0;
  justify-content: inherit;
  min-width: inherit;
  padding-left: 0px;
  padding-right: 0px;
}

.content-header-nav-filter .icon {
  position: inherit;
}

/* Unit Selection */
.unit-selection {
  position: relative;
}

.svg SVG {
  width: 100%;
}

.svg-path-color-labels {
  position: absolute;
  top: 0;
  right: 0.5rem;
  display: flex;
  gap: 2.1rem;
}

.svg-path-color-labels__item {
  display: flex;
  align-items: center;
  gap: 0.871rem;
}

.svg-path-color-labels__item-color-indicator {
  height: 1.1rem;
  width: 1.1rem;
  background: red;
  border-radius: 1.1rem;
}

.svg-path-color-labels__item-color-indicator--available {
  background: var(--color-accent);
}

.svg-path-color-labels__item-color-indicator--filtered {
  background: var(--color-primary-70);
}

.svg-path-color-labels__item-label {
  font-size: 1.5rem;
  line-height: 1.8rem;
}

/* Unit Details */
.unit-details {
  background: var(--color-primary-98);
  border-radius: 1rem;
  border: 1rem solid var(--color-primary-98);
  display: flex;
  height: 100%;
  width: 100%;
}

.unit-details-2d-3d-container {
  background: #fff;
  width: 50%;
  position: relative;
}

.unit-details-2d-3d-container__action-btns {
  position: absolute;
  bottom: 1.3rem;
  right: 1.3rem;
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
  color: var(--color-primary-56);
}

.unit-details-2d-3d-container__action-btns .icon-container {
  border: 0.5rem solid hsla(0, 0%, 98%, 1);
  border-radius: 1rem;
  background: var(--color-primary-100);
  width: 6.1rem;
  height: 6.1rem;
}

.unit-details-2d-3d-container__action-btns .icon-container.click-disabled {
  border-color: var(--color-primary-82);
  opacity: 1;
}

.unit-details-2d-3d-container__action-btns
  .unit-details-2d-3d-container__action-btn {
  line-height: 3rem;
  font-size: 2.5rem;
}

.unit-details-plank {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) var(--footer-height);
  padding: 2.5rem 5.4rem 0 5.4rem;
  width: 50%;
}

.unit-details-plank__header {
  border-bottom: var(--primary-border);
  display: flex;
  padding-bottom: 2.65rem;
}

.unit-details-plank__header .details-card {
  margin: 0 1.4rem;
}

.unit-details-plank__body {
  --available-item-background: var(--color-primary-100);
  --unavailable-item-background: var(--color-primary-98);
  padding: 2.55rem 0;
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 100%;
}

.unit-details-plank__body-header {
  display: flex;
  flex-direction: column;
}

.unit-details-plank__body-header-title {
  color: var(--color-primary-56);
  font-size: 1.8rem;
  font-weight: bold;
}

.unit-details-plank__body-header-desc {
  color: var(--color-primary-70);
  font-size: 1.2rem;
}

.floor-list-container {
  display: grid;
  grid-template-rows: 5rem minmax(1rem, 1fr);
  border-right: 0.3rem solid var(--color-primary-94);
  overflow-y: auto;
}

.floor-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: none;
  grid-gap: 2rem;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 4.65rem;
  padding-top: 1rem;
}

.floor-list .icon {
  font-size: 1.5rem;
}

.floor-list__item {
  display: flex;
  align-items: center;
  gap: 0.9rem;
}

.out-of-preference .icon--out-of-preference {
  position: absolute;
  right: -5px;
  top: -3px;
  color: hsla(38, 87%, 62%, 1);
}

.out-of-preference {
  position: relative;
}

.available-unit-background {
  background: var(--available-item-background);
  color: var(--color-primary-70);
}

.unavailable-unit-background {
  background: var(--unavailable-item-background);
  color: var(--color-primary-82);
}

.floor-list .btn {
  font-size: 1.2rem;
  border: 0.2rem solid var(--color-primary-94);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0;
  height: 3.7rem;
}

.floor-list .floor-list__item--selected {
  border-color: var(--color-accent);
  color: var(--color-accent);
}

.unit-details-plank__legends-item {
  display: flex;
  margin: 1.7rem 0;
}

.unit-details-plank__legends-item .icon-container {
  border: var(--primary-border);
  border-radius: 0.5rem;
  height: 3.7rem;
  width: 3.7rem;
}

.unit-details-plank__legends-item .icon {
  font-size: 1.4rem;
}

.unit-details-plank__legends-item-desc {
  color: var(--color-primary-70);
  font-size: 1.2rem;
  margin-left: 1.1rem;
}

.unit-details-plank__legends {
  padding-left: 4.65rem;
}

.unit-details-plank__footer {
  align-items: flex-end;
  border-top: var(--primary-border);
  display: flex;
  justify-content: space-between;
}

.unit-details-plank__summery {
  display: flex;
  flex-direction: column;
}

.unit-details-plank__summery-title {
  color: var(--color-primary-70);
  font-size: 1.2rem;
}

.unit-details-plank__summery-value {
  color: var(--color-primary-56);
  font-size: 2.3rem;
}

/* filter dropdown*/

.dropdown-content {
  background: var(--color-primary-100);
  border-radius: 1rem;
  box-shadow: 0px 0.8rem 1.6rem 0px rgba(0, 0, 0, 0.2);
  display: none;
  left: 0;
  min-width: 14.5rem;
  padding: 2rem 0;
  position: absolute;
  top: 5rem;
  z-index: 10;
}

.dropdown-content--show {
  display: block;
}

.dropdown-content__item {
  border: 0;
  color: var(--color-primary-56);
  display: block;
  padding: 1rem 1.6rem;
  padding-left: 0;
  margin: 0 auto;
  text-align: left;
  background: inherit;
  text-transform: capitalize;
}

.dropdown-content__item-desc {
  color: var(--color-primary-82);
  font-size: 1.2rem;
  display: block;
}

/* Filter Preferences */
.filter-preference-container {
  padding-top: 3.7rem;
  position: relative;
}
.preference-list {
  background: white;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  position: absolute;
}

.preference {
  border-bottom: var(--primary-border);
}
.preference-header {
  display: inline-flex;
  align-items: center;
}

.preferance-body {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 3.1rem;
  padding-right: 4.173rem;
  margin-right: 3.927rem;
  border-right: 0.2rem solid var(--color-primary-98);
  margin-bottom: 2.35rem;
}

.preferance-body--hide-right-border {
  border-right: 0;
}

.preferance-body--col-2 {
  grid-template-columns: auto auto;
}

.preference__title {
  color: var(--color-accent);
  font-size: 1.8rem;
  font-weight: bold;
}

.preference__reset {
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--color-primary-56);
  margin-left: 1.1rem;
  cursor: pointer;
}

.preference__reset .icon {
  font-size: 1.8rem;
  margin-right: 0.2rem;
}

.preference__reset--disabled {
  cursor: default;
  pointer-events: none;
  color: var(--color-primary-82);
}

.preference__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.preference__item button {
  border: 0;
  cursor: pointer;
  background: inherit;
  color: inherit;
  padding: 0;
}

.preference__item-selected {
  font-weight: bold;
}

button.preference__item-disabled {
  color: var(--color-primary-82);
  pointer-events: none;
  cursor: default;
}

.preference__item-title {
  font-size: 1.5rem;
  color: var(--color-primary-56);
  display: inline-block;
  height: 2.2rem;
  font-weight: 400;
}

.preference__item-desc {
  font-size: 1.2rem;
  color: var(--color-primary-82);
  display: inline-block;
  height: 2.4rem;
}

/*Cost Sheet*/
.costsheet {
  /* background: var(--color-primary-98); */
  /* border-radius: 1rem; */
  /* border: 1rem solid var(--color-primary-98); */
  display: flex;
  height: 100%;
  width: 100%;
}
/* 
.costsheet {
  grid-template-columns: 0.55fr 0.45fr;
  grid-template-rows: minmax(0, 1fr);
} */

.costsheet-details {
  --header-body-padding-right: 2.7rem;
  width: 55%;
  padding-right: 1.4rem;
  grid-template-rows: minmax(0, 1fr) var(--footer-height);
  display: grid;
}

.costsheet-plank {
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
}

.costsheet-image-container {
  width: 45%;
  padding-left: 1.5rem;
}

.costsheet-image-container img {
  border: solid 1rem var(--color-primary-98);
  border-radius: 1rem;
}

.costsheet-details__header {
  border-bottom: var(--primary-border);
  display: flex;
  justify-content: space-between;
  padding: 1rem var(--header-body-padding-right) 2.75rem 0;
  position: relative;
}

.costsheet-details__header-labels {
  padding-top: 1.3rem;
}

.costsheet-details__header-labels-item {
  margin-bottom: 0.125rem;
}

.costsheet-details__header-summery {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.costsheet-details__total-cost {
  font-size: 3.5rem;
}

.costsheet-details__body {
  overflow: auto;
  padding-right: var(--header-body-padding-right);
}

.costsheet-details__body-highlights {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.costsheet-details__body-section-container {
  padding-right: 7.7rem;
  margin-top: 3rem;
}

.costsheet-details__footer {
  align-items: flex-end;
  border-top: var(--primary-border);
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.scroll-more-overlay {
  position: absolute;
  top: calc(100% - 3rem);
  width: 100%;
  background: transparent
    linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 1) 100%)
    0% 0% no-repeat padding-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.scroll-more-overlay--hide {
  display: none;
}

.scroll-more-overlay--top {
  bottom: -5.2rem;
  top: inherit;
  background: transparent
    linear-gradient(0, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 1) 100%) 0% 0%
    no-repeat padding-box;
}

.costsheet-details__footer .icon-container {
  margin-left: 2.5rem;
  width: inherit;
}

.costsheet-details__footer .icon {
  font-size: 2.5rem;
}

.costsheet__price-container {
  text-align: right;
}

.costsheet__statistics-value {
  font-weight: 600;
}

.costsheet__statistics-value--large {
  font-size: 4.2rem;
}

.costsheet__statistics-value--small {
  font-size: 3rem;
}

.costsheet__statistics-value--primary {
  color: var(--color-primary-56);
}

.costsheet__statistics-value--accent {
  color: var(--color-accent);
}

.costsheet__statistics-description {
  margin-bottom: 0px;
}

.costsheet__statistics-description--large {
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--color-primary-56);
}

.costsheet__statistics-description--small {
  font-size: 1.5rem;
  font-weight: 500;
}

.costsheet__statistics-description--tagline::before {
  content: '(';
}

.costsheet__statistics-description--tagline::after {
  content: ')';
}

.costsheet__body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.costsheet__details-container {
  margin-top: 5rem;
}

.costsheet__details-heading {
  font-size: 1.8rem;
  color: var(--color-primary-56);
}

.costsheet__details-description {
  font-size: 1.2rem;
  color: var(--color-primary-70);
}

.costsheet__detail-item {
  display: flex;
  justify-content: space-between;
}

.costsheet__detail-item-value {
  font-size: 1.4rem;
  color: var(--color-primary-56);
}

.costsheet__detail-item-value--right {
  text-align: right;
}

/*Payment Plan*/

.payment-plan {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) var(--footer-height);
  overflow: hidden;
}

.payment-plan__body {
  display: flex;
  height: 100%;
  width: 100%;
}

.payment-plan-milstone-table-container {
  height: 100%;
  overflow: auto;
  position: relative;
  width: calc(100% - var(--unit-summary-card-width));
}

.payment-plan-milstone-table {
  width: 100%;
  border-collapse: collapse;
  color: var(--color-primary-56);
  font-size: 1.5rem;
  text-align: center;
}

.payment-plan-milstone-table th {
  font-weight: 700;
  padding-bottom: 4rem;
  padding-right: 5rem;
  padding-left: 0;
  background: #fff;
}

.payment-plan-milstone-table td {
  padding-bottom: 2rem;
  padding-right: 5rem;
}

.payment-plan-milstone-table__title-cell {
  width: 100%;
  text-align: left;
}

.payment-plan-milstone-table__percent-cell {
  white-space: nowrap;
}

.payment-plan-milstone-table__seemore-overlay-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  height: 7rem;
  bottom: 0;
  width: 100%;
  background: transparent
    linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 1) 100%)
    0% 0% no-repeat padding-box;
  padding-bottom: 2rem;
  font-size: 1.2rem;
  color: var(--color-primary-70);
}

.payment-plan-milstone-table__seemore-overlay--hide {
  display: none;
}

.payment-plan-milstone-table__seemore-head-overlay--hide::after,
.payment-plan-milstone-table__seemore-head-overlay--hide::before {
  display: none;
}

.payment-plan-milstone-table__seemore-head {
  position: sticky;
  top: 0;
}

.payment-plan-milstone-table__seemore-head::after {
  content: '';
  background: transparent
    linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 1) 100%) 0%
    0% no-repeat padding-box;
  height: 5rem;
  width: 100%;
  position: absolute;
  top: 10rem;
  left: 0;
}

.payment-plan__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: var(--primary-border);
}

.payment-plan__footer-action-btns {
  display: flex;
  align-items: center;
}

.payment-plan__footer-proceed-btn {
  margin: 0 3.5rem 0 2.5rem;
}

.pdf-page {
  position: relative;
  margin: 1rem auto;
  padding: 0.4in 0.3in;
  color: #333;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-family: 'DejaVu Sans', 'Arial', sans-serif;
}

/* Modal */
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */ /*TODO: create variable for z-index*/
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal--show {
  display: flex;
  align-items: center;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  border-radius: 1rem;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  z-index: 101; /* Sit on top */ /*TODO: create variable for z-index*/
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    /* top: -300px; */
    opacity: 0;
  }
  to {
    /* top: 0; */
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.text-capitalize {
  text-transform: capitalize;
}

.vertical-line {
  border: 0.1rem solid var(--color-primary-41);
  height: 4rem;
  opacity: 0.16;
  padding: 0;
  width: 0.1rem;
  margin: 0 2.5rem 0 0;
}

/*ClipLoader component - svg loading  default class style */
.css-q3o1l2 {
  margin-top: 15% !important;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.css-18btcsr {
  width: 100% !important;
}

/* Checkbox */
.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  gap: 1.2rem;
  margin-left: 1.2rem;
}

.checkbox > input {
  height: 2rem;
  width: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid var(--color-primary-56);
  border-radius: 0.25rem;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid var(--color-accent);
  background-color: var(--color-accent);
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: white;
  position: absolute;
  left: 0.4rem;
  top: 0rem;
}

.search-box {
  display: flex;
  align-items: center;
  width: 38.9rem;
  height: 5.1rem;
  padding-top: 1.4rem;
  padding-bottom: 1rem;
  /* TODO: color is not generic, check with designer*/
  color: hsla(0, 0%, 87%, 1);
  border: hsla(0, 0%, 83%, 1) 1px solid;
  font-size: 1.8rem;
  line-height: 2.4rem;
  border-radius: 0.3rem;
  padding-left: 1.8rem;
}

.search-box input {
  border: none;
  height: 100%;
  width: 100%;
  color: var(--color-primary-56);
}
