.createNewAdminModal {
  padding: 7.2rem 6.7rem 9.1rem 6.8rem;
  width: 87.5rem;
}

/*Modal Header*/
.modalHeader {
  display: flex;
  justify-content: space-between;
}

.closeIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}

.modalHeaderHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
  margin: 0;
  margin-bottom: 1.85rem;
}

.modalHeaderDes {
  color: hsla(0, 0%, 44%, 1);
  /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  opacity: 0.65;
}

/*Modal Body*/
.twoTextInputsRowContainer {
  display: flex;
  justify-content: space-between;
}

.addNameInput {
  width: 31.1rem;
}

/*Modal Footer*/
.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 5.9rem;
}

.footerDisclaimer {
  color: hsla(0, 0%, 44%, 1);
  /*TODO: Color not generic*/
  font-size: 1rem;
  line-height: 1.3rem;
  width: 31.2rem;
}

.footerAddAdminButton {
  width: 31.2rem;
}
