.unit-cart-list-by-user {
  padding: 6.6rem;
  height: 80vh;
  overflow-y: auto;
}

.unit-cart-list-by-user__header {
  display: flex;
}

.unit-cart-list-by-user__user-other-details {
  flex-basis: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 4.65rem;
  padding-top: 1.4rem;
}

.unit-cart-list-by-user .item-details-with-icon-or-thumb .icon {
  color: var(--color-accent);
}

.unit-cart-list-by-user
  .item-details-with-icon-or-thumb
  .item-details-with-icon__details-secondary {
  color: var(--color-primary-56);
}

.unit-cart-list-by-user__user-name {
  flex-basis: 40%;
  border-right: var(--primary-border);
}

.unit-cart-list-by-user__user-name .thumb {
  width: 15.4rem;
  height: 15.4rem;
}

.unit-cart-list-by-user__user-name .item-details-with-icon__details-primary {
  font-size: 4rem;
  font-family: Montserrat;
  font-weight: bold;
  color: var(--color-primary-41);
}

.unit-cart-list-by-user__user-name .item-details-with-icon__details-secondary {
  font-weight: 500;
  color: var(--color-primary-70);
}

.unit-cart-list-by-user__body {
  margin-top: 4.2rem;
}
