.registrationRenderer {
  align-items: center;
  display: flex;
}
.growth {
  color: hsla(0, 0%, 44%, 1); /*TODO: Not generic*/
  font-size: 2.2rem;
  line-height: 2.7rem;
  margin-top: 1rem;
  width: 7rem;
}
.growthIndicatoreContainer {
  align-items: center;
  display: flex;
}
.growthIndicatoreContainer p {
  font-size: 1.5rem;
  margin-bottom: 0;
}
.arrowIcon {
  font-size: 1.8rem;
  height: 2rem;
  line-height: 2.2rem;
  margin-bottom: 0.4rem;
}
.negativeGrowth {
  color: hsla(0, 100%, 80%, 1); /*TODO: Not generic*/
}
.positiveGrowth {
  color: var(--color-status-success);
}

/*Project disable state*/
.projectDisabled {
  color: var(--color-primary-70);
  font-weight: 500;
  font-size: 1.8rem;
}
