.pageContainer {
  position: relative;
  padding-bottom: 6rem;
}
.headerWrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-cp-primary-98);
  padding: 3rem 1.8rem;
}
.userImg {
  flex-basis: 15%;
  justify-content: flex-start;
}
.userImg > div {
  opacity: 1;
}
.userImg img {
  width: 100%;
  display: block;
  border-radius: 50%;
}
.notificationIcons {
  flex-basis: 75%;
  align-items: center;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.iconWrap {
  position: relative;
}
.headerIcon {
  font-size: 2rem;
  color: var(--color-cp-primary-31);
  margin-right: 1.4rem;
}
.iconWrap .headerIcon {
  margin-right: 0.2rem;
}
.notificationCount {
  align-items: center;
  /* TODO: Color is not generic */
  background-color: hsla(19, 89%, 67%, 1);
  border-radius: 50%;
  /* TODO: Color is not generic */
  color: hsla(0, 10%, 8%, 1);
  display: flex;
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 1.8rem;
  justify-content: center;
  margin-bottom: 0;
  padding: 0.1rem;
  position: absolute;
  position: absolute;
  right: -0.7rem;
  top: 0rem;
  width: 1.8rem;
}
.performanceWrap {
  padding-bottom: 2.5rem;
}
.performanceHeaderWrap {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 1.3rem;
}
.headerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewAll {
  color: var(--color-cp-primary-61);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.1rem;
}
.conatinerWrap {
  background-color: var(--color-cp-primary-98);
}
.innerWrap {
  background-color: var(--color-primary-100);
  border-radius: 2rem 2rem 0 0;
  padding: 2rem 1.8rem;
}
