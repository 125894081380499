:root {
  // Cp colors
  --color-cp-primary-31: hsla(0, 3%, 31%, 1);
  --color-cp-primary-17: hsla(0, 1%, 17%, 1);
  --color-cp-primary-97: hsla(0, 0%, 97%, 1);
  --color-cp-primary-96: hsla(0, 0%, 96%, 1);
  --color-cp-primary-43: hsla(0, 0%, 43%, 1);
  --color-cp-primary-91: hsla(0, 0%, 91%, 1);
  --color-cp-primary-98: hsla(40, 27%, 98%, 1);
  --color-cp-primary-61: hsla(20, 84%, 61%, 1);
  --color-cp-primary-35: hsla(0, 0%, 35%, 1);
  --color-cp-primary-83: hsla(0, 0%, 83%, 1);
  --color-cp-primary-53: hsla(0, 0%, 53%, 1);
  --color-cp-border-primary: hsla(0, 0%, 0%, 0.1);

  --color-cp-green-68: hsla(144, 53%, 68%, 1);
  --color-cp-green-97: hsla(143, 100%, 97%, 1);
  --color-cp-orange-67: hsla(43, 100%, 67%, 1);
  --color-cp-orange-97: hsla(43, 100%, 97%, 1);
  --color-cp-saffron-75: hsla(20, 100%, 75%, 1);
  --color-cp-saffron-97: hsla(22, 57%, 97%, 1);
  --color-cp-blue-61: hsla(248, 48%, 61%, 1);
  --color-cp-blue-98: hsla(246, 100%, 98%, 1);
  --color-cp-primary-44: hsla(0, 0%, 44%, 1);
  --color-cp-primary-32: hsla(205, 20%, 32%, 1);

  /* Border */
  --primary-cp-border-radius: 1rem;

  // status
  --color-cp-status-warning: hsla(18, 85%, 62%, 1);

  /* Indexes */
  --z-index-1:1;

  //  highlight transparent
  button:focus,
  button:active {
    -ms-touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    touch-action: manipulation;
  }
  
  *{
    -webkit-tap-highlight-color: transparent;

  }

  a:focus,
  a:active {
    -ms-touch-action: manipulation;
    outline: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }

  // Modal Mobile styles

  @media only screen and (max-width: 76.8rem) {
    .modal--show {
      align-items: flex-end;
      transition-duration: 400ms;
      transition-property: opacity, transform, visibility;
    }
    .modal-content {
      width: 100%;
      -webkit-animation-name: animatebottom;
      animation-name: animatebottom;
      margin:unset;
      border: none;
      border-radius: 1.2rem  1.2rem 0 0;
      max-height: 90vh;
      overflow: scroll;
    }
    .modal-backdrop{
      // TODO: Color is not generic
      background-color: hsla(0, 0%, 20%, 0.4);
    }
  }

  @-webkit-keyframes animatebottom {
    from {
      /* top: -300px; */
      opacity: 0;
    }
    to {
      /* top: 0; */
      opacity: 1;
    }
  }

  @keyframes animatebottom {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
}
