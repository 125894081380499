.agreement {
  color: var(--color-primary-56);
}
.agreement__content {
  padding-right: 2rem;
  padding-bottom: 3.8rem;
}

.agreement__header {
  display: flex;
  justify-content: space-between;
}
.agreement__header-left {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
}

.agreement__header-right {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
}
.agreement__header-logo-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 0.4rem;
}

.agreement__header-logo-container img {
  width: 50px;
}

.agreement__section {
  margin-top: 3.8rem;
}

.agreement__section-title {
  align-items: center;
  display: flex;
}

.agreement__section-title-dot-line {
  border-top: 0.2rem dashed var(--color-primary-82);
  flex-grow: 1;
  margin-left: 2.6rem;
}

.agreement__desc {
  display: inline-block;
  font-size: 1.5rem;
  max-width: 49.6rem;
}

.agreement__applicant-names {
  column-gap: 8.5rem;
  display: grid;
  font-size: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  line-height: 1.8rem;
  margin-top: 2.7rem;
  row-gap: 3.11rem;
}

.agreement__applicant-names-item {
  display: flex;
}

.agreement__applicant-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}

.agreement__applicant-name {
  color: var(--color-primary-56);
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.4rem;
}

.agreement__applicant-type {
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.agreement-unit-details {
  border-top: var(--primary-border);
  display: grid;
  font-size: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  line-height: 1.8rem;
  margin-top: 2.7rem;
  padding-top: 3rem;
  row-gap: 3.11rem;
}

.agreement-unit-details-cell {
  color: var(--color-primary-56);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.agreement-unit-details-cell__value-lable {
  font-weight: bold;
  font-size: 2.5rem;
}

.agreement-unit-details-cell__title-lable {
  font-size: 1.5rem;
  font-weight: bold;
}

.agreement-unit-details-cell__desc-lable {
  font-size: 1.5rem;
}

.agreement-project-details {
  margin-top: 3rem;
}

.agreement-project-details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.agreement-project-details-cell {
  display: flex;
  flex-direction: column;
}

.agreement-project-details-cell__title-lable {
  font-size: 1.5rem;
  line-height: 1.8rem;
}

.agreement-project-details-cell__value-lable {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2.2rem;
}

.agreement-applicant {
  margin: 2.7rem 0;
}

.agreement-applicant__title {
  color: var(--color-primary-56);
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2.3rem;
}

.agreement-applicant__body {
  column-gap: 8.5rem;
  display: grid;
  font-size: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  line-height: 1.8rem;
  row-gap: 3.11rem;
}

.agreement-applicant__body-cell--full-width {
  grid-column: 1 / -1;
  max-width: 100%;
}

.agreement-applicant__body-cell {
  border-bottom: var(--primary-border);
  display: flex;
  flex-direction: column;
  min-height: 3.72rem;
}

.agreement-applicant__body-cell-lable {
  color: var(--color-primary-82);
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.agreement-applicant__body-cell-value {
  color: var(--color-primary-56);
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 1.2rem;
  padding-bottom: 0.5rem;
  text-transform: capitalize;
  width: 100%;
}

.agreement__edit-btn {
  margin-left: 1rem;
}
