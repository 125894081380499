.container {
  background-color: var(--color-primary-100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
}

.container header {
  color: var(--color-cp-primary-17);
  font-family: PerfectlyAmicable, sans-serif;
  font-size: 5rem;
  line-height: 4.4rem;
  text-transform: lowercase;
}

.container main li {
  color: rgba(121, 121, 121, 1); /* TODO: Make color generic as per theme */
  font-size: 1.8rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 1.795rem;
}

.description {
  color: var(--color-cp-primary-31);
  font-family: 'SF-UI-medium', sans-serif;
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin-bottom: 2rem;
  max-width: 80%;
  font-weight: bold;
}

.title {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 3.8rem;
  font-weight: bold;
  line-height: 4.5rem;
  margin-bottom: 1rem;
  max-width: 75%;
}
.btnWrapper {
  max-width: 50%;
}

.getStartedBtn {
  background-color: var(--color-cp-primary-17);
  border-radius: 1.2rem;
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.8rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}
