/*Scheduled Demo Modal*/
.modalAdditionalClasses {
  width: 88.4rem;
}

/*Modal header*/
.modalHeader {
  padding: 7.2rem 6.6rem 0 6.6rem;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0;
  /*TODO Font is Not generic(poppins insted of roboto in design)*/
}
.modalHeadingContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.crossIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.headerDes {
  color: hsla(0, 0%, 44%, 1); /*TODO: Not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-block: 1.85rem 3.2rem;
  width: 50.5rem;
}

/*Body*/
.bodyContainer {
  display: flex;
  justify-content: space-between;
}
.bodyLeftContainer {
  flex: 1;
  width: 31.2rem;
}
.bodyRightContainer {
  flex: 1;
  margin-left: 9.1rem;
}

/*Modal Main Section*/
.modalMainContainer {
  padding: 0 2.8rem 0 6.6rem;
}
.individualDetailsContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.detailsTitle {
  color: hsla(0, 0%, 44%, 1); /*TODO: Not generic*/
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin-bottom: 0.3rem;
}
.detailsDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO: Not generic*/
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin: 0;
}
.detailsValue {
  color: hsla(0, 0%, 44%, 1); /*TODO: Not generic*/
  font-size: 2.2rem;
  line-height: 2.7rem;
  margin: 0;
  font-weight: 500;
}
/* this class will be used when we connect application with database */
.greenDetailsDesc {
  color: var(--color-status-success);
}
.passwordContainer {
  background-color: var(--color-primary-98);
  display: flex;
  height: 9.7rem;
  justify-content: space-between;
  margin-top: 3.25rem;
  max-width: 36.7rem;
  padding: 2.85rem 6.6rem 2.95rem 6.6rem;
}
/*Modal Footer*/
.modalFooter {
  margin-top: 4.5rem;
  padding: 0 0 0 6.6rem;
  display: flex;
  flex-direction: column;
}
.modalGenericButton {
  background-color: var(--color--secondary-205);
  border-radius: 0.2rem;
  border: none;
  color: var(--color-primary-100);
  font-size: 1.5rem;
  height: 5.3rem;
  line-height: 1.8rem;
  padding: 1.6rem 5.6rem;
  width: 31.2rem;
}
.buttonRed {
  background-color: var(--color-status-error);
  margin-block: 0.85rem;
}
.buttonTransparent {
  background-color: transparent;
  color: var(--color--secondary-205);
  margin-bottom: 4.9rem;
}

/*body right container*/
.demoMessageContainer {
  background-color: var(--color-primary-98);
  border-radius: 0.2rem;
  border: 0.1rem solid var(--color-primary-82);
  height: 41.3rem; /*remove*/
  padding: 2.08rem 2.7rem 0 2.4rem;
  width: 33.1rem;
}
.demoMessage {
  color: hsla(0, 0%, 44%, 1); /*TODO: color not generic*/
  font-size: 1.7rem;
  height: 28.7rem;
  line-height: 2.1rem;
  margin-bottom: 5rem;
}
.boldDemoMessage {
  font-weight: 500;
}
.copyToClipboardBtn {
  color: var(--color-primary-41);
  cursor: pointer;
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  height: 5.3rem;
  justify-content: center;
  line-height: 1.8rem;
  margin-bottom: 2.2rem;
  padding-block: 1.7rem;
  width: 100%;
}
.copyIcon {
  color: var(--color-primary-41);
  font-size: 2.2rem;
  line-height: 1.8rem;
  margin-right: 1.1rem;
}
.rescheduleDemoBtnContainer {
  height: 5.3rem;
  margin-left: -2.4rem;
  margin-top: 2.4rem;
  width: 33.3rem;
}
.rescheduleDemoBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  width: 100%;
}
