.item-details-with-icon-or-thumb {
  display: flex;
  align-items: center;
  gap: 2.9rem;
}

.item-details-with-icon-or-thumb .icon {
  font-size: 2.8rem;
}

.item-details-with-icon__details {
  display: flex;
  flex-direction: column;
}

.item-details-with-icon__details-primary {
  font-size: 1.8rem;
}

.item-details-with-icon__details-secondary {
  font-size: 2.2rem;
  font-weight: bold;
}
