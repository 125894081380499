.inputContainer {
  margin-top: 2rem;
  position: relative;
  width: 100%;
}

.inputLabel {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  color: var(--color-cp-primary-43);

  height: 1.9rem;
}

.inputLabel[data-app="RELATA"] {
  color: var(--color-primary-82);
  font-size: 1rem;
  margin-left: 2rem;
}

.inputBox {
  display: flex;
  min-height: 5.1rem;
  min-width: 100%;
  justify-content: space-between;

  border: 1px solid hsla(0, 0%, 84%, 1);
  border-radius: 0.5rem;

}

.inputBox[data-app="RELATA"] {
  border-radius: unset;
}

.inputBoxLeftContent {
  display: flex;
  align-items: center;
  width: 98%;
  margin: 0 auto;
}

.inputField {
  display: none;
}

.fileNameLabel {
  width: 98%;

  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-cp-primary-43);

  cursor: pointer;


  padding-inline: 1.78rem 1.51rem;
  padding-left: 1.6rem;
  margin: 0 auto
}

.inputError {
  position: absolute;
  top: 6rem;

  color: red;
}

.endIcon {
  color: hsla(205, 20%, 32%, 1);
  font-size: 2.2rem;
  line-height: 1.8rem;

  margin-right: 2rem;
  padding: 1.38rem 0rem 1.51rem 0rem;
}

::-webkit-file-upload-button {
  display: none;
}