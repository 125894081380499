.cardContainer {
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 2rem;
}

.cardWrapper {
  background-color: var(--color-cp-primary-98);
  border-radius: 1rem;
  margin-bottom: 2.2rem;
  width: 100%;
}

.textWrapper {
  padding: 1rem 1.3rem 1.3rem 1.3rem;
}

.headWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.propertyPrice {
  color: var(--color-cp-primary-61);
  font-family: 'SF-UI', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
}

.imageWrapper {
  position: relative;
  width: 100%;
  background-color: var(--color-primary-100);
}
.imageWraperInner {
  opacity: 1;
}
.favoriteIcon {
  color: var(--color-primary-100);
  font-size: 2rem;
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
}

.imageWrapper img {
  border-radius: 1rem 1rem 0 0;
  display: block;
  max-height: 20rem;
  min-width: calc(100vw - 3.6rem);
  object-fit: cover;
  width: 100%;
}

.propertyName {
  color: var(--color-cp-primary-17);
  flex-basis: 70%;
  font-family: 'SF-UI', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
}

.propertyLocation {
  color: var(--color-cp-primary-43);
  font-size: 1rem;
  padding: 0.6rem 0;
}

.propertySpecs li {
  list-style: disc;
  margin: 0 1.3rem;
  position: relative;
}

.propertySpecs {
  color: var(--color-cp-primary-43);
  display: flex;
  font-size: 1rem;
}
