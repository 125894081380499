p {
  margin-bottom: 0;
}

.performanceCard {
  border-radius: 1.1rem;
  border: 1px solid var(--color-cp-primary-96);
  flex: 0 0 auto;
  margin-right: 1.1rem;
  max-width: 13.6rem;
  padding: 1rem 1.1rem;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}

.performanceCard:first-child {
  margin-left: 0.4rem;
}

.performanceIcon {
  border-radius: 0.6rem;
  color: var(--color-primary-100);
  font-size: 1.7rem;
  padding: 0.7rem;
}

.performanceIcon.green {
  background-color: var(--color-cp-green-68);
}

.performanceIcon.orange {
  background-color: var(--color-cp-orange-67);
}

.performanceIcon.safforn {
  background-color: var(--color-cp-saffron-75);
}

.performanceTitle {
  color: var(--color-cp-primary-31);
  font-size: 1.1rem;
  padding-top: 0.9rem;
  font-family: 'SF-UI-regular', sans-serif;
}

.performanceCount {
  font-size: 3.1rem;
  font-family: 'Roboto', sans-serif;
}

.performanceType {
  color: var(--color-cp-primary-17);
  font-size: 1.8rem;
  font-family: 'SF-UI-regular', sans-serif;
}

.completedTask {
  color: var(--color-cp-primary-43);
  font-size: 1rem;
  font-family: 'SF-UI-regular', sans-serif;
}