.ripple {
  width: 2rem;
  height: 2rem;
  position: absolute;
  background: var(--color-primary-72);
  display: block;
  border-radius: 50%;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(10);
    opacity: 0.175;
  }
  100% {
    transform: scale(55);
    opacity: 0;
  }
}
