.organizationListHeader {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.3rem;
  width: 100%;
}
.dashboardHeading {
  color: var(--color--secondary-205);
  font-family: Poppins, Roboto, sans-serif;
  font-size: 4rem;
  font-weight: 600;
  line-height: 6rem;
  margin: 0;
}
.dashboardDesc {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.dashboardButtonsContainer {
  display: flex;
}
.boldTextForDashboardDesc {
  font-weight: 700;
  margin-left: 0.4rem;
}
.searchContainer {
  max-width: 38.9rem;
}

.dashboardButtonsContainer {
  width: 28rem;
}
