.admin .nav__item {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.2rem;
}

.admin .nav__item .icon {
  font-size: 2.2rem;
}

.admin .thumb {
  height: 3.5rem;
  width: 3.5rem;
}
