.container {
  margin-top: 2rem;
}
.label {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  color: var(--color-cp-primary-43);
  margin-bottom: 0.7rem;
}
.filterWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.option {
  border: 0.5px solid var(--color-cp-primary-83);
  border-radius: 0.6rem;
  font-size: 1.3rem;
  font-family: 'SF-UI-regular', sans-serif;
  color: var(--color-primary-56);
  transition: all 0.4s ease;
  text-align: center;
  padding: 0.7rem 1.6rem;
  margin-bottom: 1.1rem;
  min-width: 8.1rem;
  margin-left: 1rem;
}
.option:nth-child(4n),
.option:first-child {
  margin-left: 0;
}
.option.active {
  background-color: var(--color-cp-primary-91);
  color: var(--color-cp-primary-35);
  border: 1px solid var(--color-cp-primary-91);
}
.inputFieldError {
  line-height: 1.3rem;
  margin-bottom: 0.3rem;
  max-height: 1.7rem;
  /* position: absolute; */
  text-align: right;
  /* color is not generic  */
  color: hsla(0, 87%, 61%, 1);
  font-size: 1.1rem;
  font-family: 'SF-UI-regular', sans-serif;
}
