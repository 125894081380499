.adminLoginRightContainer {
  background-color: var(--color-primary-100);
  border-radius: 1rem;
  flex: 1;
  max-width: 44.3rem;
  padding: 7.8rem 6.5rem 4rem 6.6rem;
}
.loginDisclaimer {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not geneirc*/
  font-size: 1rem;
  line-height: 1.3rem;
}
.termsAndConditionsLink {
  color: var(--color--secondary-205);
}

/*Login Form Styling*/
.loginFormHeading {
  /* TODO: Font not generic as per theme */
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0 0 1.9rem 0;
}
.loginFormDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO:color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-bottom: 1.9rem;
}
.accessRevokedMessage {
  align-items: center;
  background-color: hsla(0, 71%, 95%, 1); /*TOD:Color not generic*/
  border-radius: 0.2rem;
  color: var(--color-status-error);
  display: flex;
  font-size: 1.2rem;
  height: 5.5rem;
  padding: 0.9rem 1.4rem 0.9rem 1.387rem;
  width: 31.2rem;
}
.errorIcon {
  font-size: 2.2rem;
  line-height: 1.8rem;
  margin-right: 1.313rem;
}
.loginButton {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  margin-top: 4.6rem;
}
.forgotPasswordButton {
  background-color: transparent;
  color: var(--color--secondary-205);
  margin-block: 0.8rem 3.1rem;
}

.passwordResetSuccessIconContainer {
  margin-top: 4.5rem;
  text-align: center;
}
.passwordResetSuccessIcon {
  color: var(--color-status-success);
  font-size: 12.4rem;
}

.disabledForgotPasswordButton {
  background-color: transparent;
  color: var(--color--secondary-205);
  opacity: 0.2;
}

@media (max-width: 900px) {
  .adminLoginRightContainer {
    width: 100%;
    padding: 2.5rem 1.8rem;
  }
  .loginFormHeading {
    font-size: 2.3rem;
    line-height: 3.3rem;
    margin-bottom: 1rem;
  }
  .loginFormDesc {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
}
