.leads-dashboard-container {
  font-family: Roboto;
}
.leads-dashboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .names-container {
    flex-basis: 82rem;
    .project-name {
      font-size: 4rem;
      line-height: 6rem;
      font-family: 'Poppins';
      font-weight: 600;
      letter-spacing: 0px;
      color: var(--color--secondary-205);
      text-transform: capitalize;
    }
    .brand-name {
      font-weight: 700;
      color: hsla(0, 0%, 70%, 1);
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    .leads-list {
      display: flex;
      width: fit-content;
      padding-right: 2rem;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      padding-left: 1rem;
      margin-top: 1.2rem;
      border-radius: 5rem;
      background-color: var(--color-primary-94);

      .leads-names-container {
        margin-left: 1.5rem;
        color: var(--color-primary-70);
        font-size: 1.6rem;
        line-height: 1.9rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .leads-data {
          font-weight: 700;
          flex: none;
        }
      }
    }
  }

  .data-container {
    flex-basis: 96rem;
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
    > .summary-component {
      width: 26rem;
    }
  }
}

.leads-dashboard-container__tabs {
  margin-top: 2.5rem;
}

// grid overrides
.active-leads-grid {
  margin-block: 4rem;
}
.last-action-cell {
  .last-action-name {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin: 0;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .last-action-date {
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: var(--color-primary-82);
    margin: 0;
  }
}

.ag-grid-header-indicator-icon {
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: var(--color-primary-56);
}

.ag-theme-alpine .ag-ltr .ag-cell.last-action {
  border-right: 1px solid var(--color-primary-82);
}
.ag-theme-alpine .ag-header-row .ag-header-cell.last-action {
  border-right: 1px solid var(--color-primary-82);
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.ag-grid-column-custom-cell {
  .ag-react-container {
    color: var(--color-primary-82);
    font-size: 3.5rem;
    line-height: 4.2rem;
    .custom-cell-container {
      cursor: pointer;
      width: 4.8rem;
      height: 4.8rem;
      background-color: var(--color-primary-98);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &.expanded {
        background-color: var(--color-primary-82);
        .icon.material-icons {
          color: var(--color-primary-100);
        }
      }
    }
  }
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  &.ag-grid-column-custom-cell,
  &.lead-enquiry-status {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.leads-details-enquiries {
  .ag-cell,
  .ag-row,
  .ag-root-wrapper {
    border: none;
    background-color: var(--color-primary-98);
  }
}

.lead-status-column {
  font-weight: 700;
  &.explored {
    color: var(--color-primary-56);
  }
  &.blocked {
    color: var(--color-yellow);
  }
  &.paymentdone {
    color: var(--color-status-success);
  }
  // &.kyccompleted{}
}

.lead-enquiry-status {
  .ag-react-container {
    display: flex;
  }
  .icon {
    font-size: 4.6rem;
  }
  &.explored {
    color: var(--color-primary-56);
  }
  &.blocked {
    color: var(--color-primary-56);
  }
  &.paymentdone {
    color: var(--color-status-success);
  }
  &.kyccompleted {
    color: var(--color-yellow);
  }
}

// actions column
.leads-enquiries-actions-column {
  display: flex;
  gap: 3rem;
  align-items: center;
  .action-button {
    width: 17.6rem;
    height: 5.3rem;
    // border: 1px solid black;
    padding-left: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: #6fc70a;
    color: #ffffff;
    .icon {
      padding-right: 1.4rem;
      font-size: 2.2rem;
    }
    .button-title {
      font-size: 1.8rem;
    }
  }
}
