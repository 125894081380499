.listContainer {
  position: relative;
}

.leadContainer {
  align-items: center;
  border-bottom: 1px solid var(--color-cp-primary-96);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0.7rem 0;
}

.leadDetails {
  display: flex;
  flex-basis: 70%;
  flex-flow: column;
}

.adress {
  -webkit-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--color-cp-primary-43);
  display: -webkit-box;
  font-size: 1rem;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.searchIcon {
  color: var(--color-cp-primary-31);
  font-size: 2rem;
}

.tabItem {
  color: var(--color-cp-primary-17);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 4rem;
  transition: all 0.4s ease;
}

.tabItem:last-child {
  margin-right: 0;
}

.tabs {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 2rem 0;
}

.tabItem[data-tab-active='false'] {
  color: var(--color-cp-primary-43);
  font-family: 'SF-UI-regular', sans-serif;
  font-weight: unset;
}
.statusIcon.iconRed {
  color: var(--color-status-error);
}

.statusIcon.iconPaleBlue {
  color: hsla(186, 100%, 43%, 1);
  /* TODO: Color is not generic */
}

.statusIcon.iconOrange {
  color: var(--color-cp-status-warning);
}

.statusIcon {
  font-size: 1.2rem;
  margin-right: 0.4rem;
}

.addressWrap {
  align-items: center;
  display: flex;
  max-width: 85%;
}

.leadQRCode img {
  width: 100%;
  display: block;
}

.leadQRCode {
  flex-basis: 20%;
  font-weight: bold;
  padding: 0.2rem;
}

.leadName {
  color: var(--color-cp-primary-31);
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
}

.mobile {
  color: var(--color-cp-primary-43);
  font-size: 1rem;
  margin-bottom: 0.2rem;
}

.leadItemWrap {
  margin-bottom: 7rem;
}

.emptyLeads {
  font-size: 1.3rem;
  line-height: 50vh;
  text-align: center;
}