.inputContainer {
  margin-top: 2.4rem;
  position: relative;
  width: 100%;
}

.inputLable {
  color: var(--color-primary-82);
  font-size: 1rem;
  height: 1.9rem;
  margin-left: 2rem;
}
.inputBox {
  border-radius: 0.2rem;
  border: var(--color-primary-82) 0.1rem solid;
  display: flex;
  min-height: 5.1rem;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}
.inputBoxLeftContent {
  display: flex;
  width: 100%;
}
.startIconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  width: 3.8rem;
}
.primaryStartIconContainer {
  background-color: var(--color-primary-98);
  border-right: 0.1rem solid var(--color-primary-82);
}
.secondaryStartIconContainer {
  background-color: transparent;
}
.primaryStartIcon {
  font-size: 1.5rem;
  color: var(--color-primary-56);
}
.secondaryStartIcon {
  font-size: 2.5rem;
  margin-left: 1.8rem;
  /*TODO: Color not generic*/
  color: hsla(0, 0%, 87%, 1);
}
.inputField {
  /* TODO: Color is not generic */
  border: none;
  color: #405361;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-inline: 1.78rem 1.51rem;
  padding-left: 1.6rem;
  width: 100%;
}
.errorIcon {
  font-size: 2.2rem;
  line-height: 1.8rem;
  padding: 1.38rem 0rem 1.51rem 0rem;
  pointer-events: none;
  margin-right: 2rem;
  color: var(--color-status-error);
}
.endIcon {
  color: hsla(205, 20%, 32%, 1); /*not generic*/
  font-size: 2.2rem;
  line-height: 1.8rem;
  margin-right: 2rem;
  padding: 1.38rem 0rem 1.51rem 0rem;
}
.inputBox .successIcon {
  color: var(--color-status-success);
}
.inputFieldError {
  color: var(--color-status-error);
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 0.3rem;
  margin-top: 0.4rem;
  max-height: 1.7rem;
  position: absolute;
  right: 0.5rem;
  text-align: right;
}
input :disabled {
  background-color: var(--color-primary-95);
}
.defaultBottomMessage {
  margin: 0;
}
.startText {
  align-items: center;
  color: var(--color-cp-primary-32);
  display: flex;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-left: 1.6rem;
}
.startText ~ .inputField {
  padding-left: 0.3rem;
  width: 90%;
}
.startText.disabledBg {
  /* TODO: color is not generic */
  background-color: hsla(0, 0%, 97%, 1);
}
/* cr App styles */
.crApp.inputContainer {
  margin-top: 2rem;
}
.crApp .inputLable {
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.3rem;
  margin-left: 0;
  color: var(--color-cp-primary-43);
}
.crApp .inputBox {
  border-radius: 0.5rem;
  /* color is not generic */
  border-color: hsla(0, 0%, 84%, 1);
  height: 5.3rem;
}
.crApp .startText ~ .inputField {
  width: 85%;
  color: var(--color-cp-primary-31);
  font-family: 'SF-UI-regular', sans-serif;
  font-size: 1.5rem;
}
.crApp .inputFieldError {
  /* color is not generic  */
  color: hsla(0, 87%, 61%, 1);
  font-size: 1.1rem;
  font-family: 'SF-UI-regular', sans-serif;
}
.crApp .errorIcon {
  margin-right: 1rem;
  padding: 1.5rem 0rem 1.5rem 0rem;
}
.crApp .inputBoxLeftContent {
  width: 98%;
  margin: 0 auto;
}
.crApp input:disabled {
  background-color: hsla(0, 0%, 97%, 1);
}
.crApp .secondaryStartIcon {
  color: var(--color-cp-primary-31);
  font-size: 2rem;
}
.crApp input::placeholder {
  color: var(--color-cp-primary-43);
}
.cpDisabled {
  background-color: hsla(0, 0%, 97%, 1);
}
/* cr App styles */

@media (max-width: 900px) {
  .inputContainer {
    margin-top: 2rem;
  }
}
