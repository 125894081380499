.project-overview-analytics {
  margin-top: 3rem;
  display: flex;
}

.project-overview-analytics__statistics {
  flex-basis: 21.35%;
  gap: 5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid hsla(0, 0%, 83%, 1);
}

.project-overview-analytics__users-across-projects {
  flex-basis: 17.45%;
  display: flex;
  flex-direction: column;
}

.project-overview-analytics__title {
  font-size: 2rem;
  font-family: Roboto;
  font-weight: 700;
  line-height: 2.6rem;
  color: hsla(0, 0%, 57%, 1);
  padding-left: 7.15rem;
}

.project-overview-analytics__users-across-projects-chart {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-overview-analytics__demos {
  flex-basis: 61.2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
