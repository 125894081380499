.container {
  align-items: center;
  background-color: var(--color-cp-primary-97);
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 6.4rem;
  justify-content: space-around;
  left: 0;
  position: fixed;
  right: 0;
}
