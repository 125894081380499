.dashbaord-sidebar {
  background: var(--color-primary-98);
  flex-basis: 18%;
  padding-top: 3.2rem;
}

.dashbaord-sidebar-item {
  display: flex;
  align-items: center;
  padding: 0 2.9rem;
  margin: 2.2rem 3.1rem;
  color: var(--color-primary-82);
  cursor: default;
  pointer-events: none;
}

.dashbaord-sidebar-item--enabled {
  color: var(--color-primary-56);
  cursor: pointer;
  pointer-events: inherit;
}

.dashbaord-sidebar-item--active {
  background: var(--color-primary-100);
  border-radius: 1rem;
  color: var(--color-accent);
}

.dashbaord-sidebar-item--explored-unit {
  border-top: var(--primary-border);
  border-bottom: var(--primary-border);
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
}

.dashbaord-sidebar-item--explored-unit::after {
  /* border: 3px solid hsla(0, 0%, 95%, 1); */
  border-top: var(--primary-border);
  border-bottom: var(--primary-border);
}

.dashbaord-sidebar-item__label {
  margin-left: 2.2rem;
}
