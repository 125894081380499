/*Header*/

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.checkIcon {
  color: var(--color-status-success);
  font-size: 8.3rem;
  line-height: 4.7rem;
  margin-bottom: 1.95rem;
}
.headerTitle {
  color: var(--color--secondary-205);
  font-family: 'Poppins';
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.8rem;
  margin: 0;
}
.headerDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic`*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-top: 1.85rem;
  margin: 0;
  opacity: 0.65;
  text-align: center;
}

/*Main*/
.main {
  border-radius: 0.3rem;
  border: 0.3rem solid hsla(0, 0%, 97%, 1); /*TODO: Border color not generic*/
  margin-top: 2.85rem;
  padding: 3.75rem 6.4rem 2.75rem 5.4rem;
  width: 56.9rem;
}
.mainHeading {
  color: var(--color--secondary-205);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}
.personalInfoSection {
  border-bottom: 0.1rem dashed hsla(0, 0%, 44%, 1); /*TODO:Border Color not generic`*/
  margin-bottom: 3.1rem;
}
.detailsHeading {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic`*/
  font-size: 1rem;
  line-height: 1.3rem;
}
.detailsValue {
  color: hsla(0, 0%, 44%, 1); /*TODO:Color not generic`*/
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.1rem;
}
.individualDetailsSet {
  margin-bottom: 1.1rem;
  width: 50%;
}
.detailsRow {
  display: flex;
  justify-content: space-between;
}

/*Footer*/
.footer {
  display: flex;
  justify-content: space-between;
  margin-block: 2.6rem 0.4rem;
}
.footerButton {
  width: 15.5rem;
}

@media (max-width: 900px) {
  .successSummery {
    width: 100%;
  }
  .checkIcon {
    font-size: 5rem;
    line-height: 6rem;
  }
  .headerTitle {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }
  .headerDesc {
    font-size: 1.3rem;
    line-height: 2.2rem;
    max-width: 80%;
  }
  .main {
    width: 100%;
    padding: 1rem;
  }
  .mainHeading {
    font-size: 1.4rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
  .detailsValue {
    font-size: 1.3rem;
  }
  .personalInfoSection {
    margin-bottom: 2rem;
  }
  .footerButton {
    width: 45%;
    height: 4rem;
    padding-block: unset;
  }
}
