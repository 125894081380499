.button {
  border: none;
  border-radius: 0.2rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-block: 1.6rem;
  width: 100%;
  height: 5.3rem;
  text-align: center;
  font-weight: 500;
  overflow: hidden;
  position: relative;
}

.primary {
  background-color: var(--color--secondary-205);
  color: var(--color-cp-primary-97);
}
.success {
  background-color: var(--color-status-success);
  color: var(--color-primary-100);
}
.danger {
  background-color: var(--color-status-danger);
  color: var(--color-primary-100);
}
.warn {
  background-color: var(--color-status-warning-low);
  color: var(--color-primary-100);
}
.secondary {
  background-color: var(--color-primary-94);
  color: var(--color-black);
}
.ghost {
  background-color: transparent;
}
.button:disabled {
  cursor: not-allowed;
}

/* Styles for cp Button  */
.cpButton.primary {
  background-color: var(--color-cp-primary-17);
  color: var(--color-primary-100);
}
.cpButton.button {
  border-radius: 1.2rem;
  font-family: 'SF-UI-medium', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.cpButton.button:disabled {
  /* TODO: Color is not generic */
  background-color: hsla(0, 0%, 84%, 1);
}
/* Styles for cp Button  */
