.admin .content {
  flex-basis: 100%;
  // padding: 4.65rem;
  padding: 0;
}

.admin .content-body {
  // padding-top: 0rem;
  padding: 3.3rem;
}

// common admin components page
.common-admin-components-container {
  margin: 2rem;
}

.common-admin-components-container__item {
  margin: 2rem;
}

.common-admin-components-container__label {
  margin: 2rem;
  font-weight: 700;
}
