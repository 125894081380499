.superAdminProjectListHeader {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.3rem;
  width: 100%;
}
.dashboardHeading {
  font-family: Poppins, Roboto, sans-serif;
  color: var(--color--secondary-205);
  font-size: 4rem;
  line-height: 6rem;
  margin: 0;
  font-weight: 600;
}
.dashboardDesc {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.dashboardButtonsContainer {
  display: flex;
}
.boldTextForDashboardDesc {
  font-weight: 700;
  margin-left: 0.4rem;
}
.searchContainer {
  max-width: 38.9rem;
}
.searchIcon {
  color: hsla(0, 0%, 87%, 1); /*TODO: Not generic*/
  font-size: 2.5rem;
  padding: 1.2rem 1.5rem 1.2rem 1.8rem;
}
.searchInput {
  border: none;
  font-size: 1.8rem;
  outline: none;
  padding-block: 1.4rem;
  width: 100%;
}
::placeholder {
  color: hsla(0, 0%, 87%, 1); /*TODO: Not generic*/
}

.createNewProjetButton {
  background-color: var(--color--secondary-205);
  border-radius: 0.2rem;
  border: none;
  color: var(--color-primary-100);
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 1.6rem 5.6rem;
  margin-left: 4rem;
  width: 25.8rem;
}

/*Project added successfully Modal*/
.projectAddedSuccessfullyModalAdditionalClasses {
  max-width: 44.3rem;
}
.projectAddedModalHeader {
  padding: 7.2rem 6.6rem 0rem 6.6rem;
}
.projectAddedModalHeaderTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.95rem;
}
.projectAddedModalHeading {
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 3.8rem;
}
.modalCloseIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.projectAddedModalDesc {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color not generic*/
  font-size: 1.5rem;
  line-height: 2.4rem;
  opacity: 6.5;
}
.successIcon {
  color: var(--color-status-success);
  font-size: 13.2rem;
  line-height: 13.2rem;
  margin-block: 8.65rem;
}
.projectAddedModalBody {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.projectDetails {
  background-color: var(--color-primary-98);
  margin-bottom: 5.9rem;
  padding: 3.9rem 6.6rem 4.75rem 6.6rem;
  width: 100%;
}
.projectLabel {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 0.9rem;
  margin: 0;
}
.projectName {
  color: var(--color--secondary-205);
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 3.2rem;
  margin: 0;
}
.developerName {
  color: var(--color-primary-70);
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin-bottom: 3.4rem;
}
.individualProjectDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.85rem;
}
.projectIdLabel {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2reem;
  margin-bottom: 0.3rem;
  margin: 0;
}
.projectIdDesc {
  color: var(--color-primary-70);
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.projectId {
  color: var(--color-primary-56);
  font-size: 1.4rem;
  line-height: 2.7rem;
}
.projectLocation {
  font-size: 2.2rem;
}
.projectAddedModalFooter {
  margin: 0 6.6rem 5.7rem 6.6rem;
  width: 31.2rem;
}
.projectAddedModalFooter div {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 100%;
  padding: 0 8rem;
}
.copyProjectIdIcon {
  color: var(--color-primary-41);
  font-size: 2.2rem;
  line-height: 1.8rem;
  margin-right: 2.118rem;
}
.copyButtonText {
  color: var(--color-primary-41);
  font-size: 1.3rem;
  line-height: 1.8rem;
}
