/*Executive Dashboard Header left content*/
.dashboardHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.dashboardHeaderTitle {
  font-family: Poppins, Roboto, sans-serif;
  font-weight: 800;
  color: var(--color--secondary-205);
  font-size: 4rem;
  line-height: 6rem;
  margin-top: 0;
  text-transform: capitalize;
}
.dashboardHeaderDesc {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 0;
}
.dashboardBoldDes {
  font-weight: 700;
}

/*Executive Dashboard Header Right content*/
.headerRightContent {
  align-items: center;
  display: flex;
  justify-content: center;
}
.connectBtnContainer {
  background-color: var(--color-primary-94);
  border-radius: 3.4rem;
  display: flex;
  display: flex;
  height: 6.8rem;
  padding: 0.8rem 0.9rem;
  width: 56.1rem;
}
.upcommingMeetingMessageContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.clockIcon {
  color: var(--color--secondary-205);
  font-size: 4.7rem;
  line-height: 1.8rem;
}
.upcommingMeetingMessage {
  /*TODO: Font is not generic (used poppins insted of roboto in design)*/
  color: var(--color--secondary-205);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.8rem;
  margin: 0 1.4rem;
  width: 29.8rem;
}
.connetcNowBtn {
  background-color: var(--color-status-success);
  border-radius: 2.6rem;
  border: none;
  color: var(--color-primary-100);
  font-size: 1.5rem;
  height: 5.2rem;
  padding: 1.5rem 3.3rem 1.6rem 3.4rem;
  width: 16.9rem;
}
.calenderIcon {
  color: var(--color--secondary-205);
  font-size: 4.7rem;
  line-height: 1.8rem;
  margin-left: 2.7rem;
}
.shareDemoBtnConttainer {
  width: 55rem;
  margin-right: 2rem;
  display: flex;
}
.shareDemoBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  margin-left: 2rem;
}

.hostPasswordContainer {
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.hostPassword {
  margin: 0;
  margin-right: 1rem;
}
.copyIcon {
  font-size: 2rem;
  transition: transform ease 0.3s;
}
.copyIcon:hover {
  transform: scale(1.1);
}
.copyIcon:active {
  transform: scale(0.92);
}
/*Executive Dashboard Body*/
.body {
  display: flex;
  margin-block: 7rem 8.5rem;
}
.embedMessageContainer {
  background-color: var(--color-primary-98);
  height: 123.3rem;
  width: 100%;
}
.embedMessage {
  color: var(--color-primary-70);
  font-size: 1.8rem;
  line-height: 2.2rem;
}

.noVideoLinkmodalAdditionalClasses {
  padding: 4rem;
  width: 90rem;
}
.noVideoModalTitleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.noVideoModalTitle {
  color: var(--color--secondary-205);
  font-family: Poppins, sans-serif;
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0;
}
.noVideoModalOkayBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  margin-top: 3rem;
  max-width: 25rem;
}
.noVideoModalBody {
  display: flex;
  justify-content: center;
}

/*Helper classes*/
.boldText {
  font-weight: 700;
}
