.admin-sidebar {
  background: var(--color-primary-100);
  flex-basis: 5%;
  padding-top: 3.2rem;
  border-right: var(--primary-border);
  max-width: 10rem;
}
.admin-sidebar-item {
  display: flex;
  align-items: center;
  margin: 0 3.1rem 8.4rem 3.1rem;
  color: var(--color-primary-82);
  cursor: default;
  pointer-events: none;
}
.admin-sidebar-item > span {
  font-size: 2.5rem;
  line-height: 3rem;
}

.admin-sidebar-item--enabled {
  color: var(--color-primary-56);
  cursor: pointer;
  pointer-events: inherit;
}

.admin-sidebar-item--active {
  background: var(--color-primary-100);
  border-radius: 1rem;
  color: var(--color--secondary-205);
}

.admin-sidebar-item--explored-unit {
  border-top: var(--primary-border);
  border-bottom: var(--primary-border);
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
}

.admin-sidebar-item--explored-unit::after {
  /* border: 3px solid hsla(0, 0%, 95%, 1); */
  border-top: var(--primary-border);
  border-bottom: var(--primary-border);
}

.admin-sidebar-item__label {
  margin-left: 2.2rem;
}
