.executive-details-card {
  display: inline-flex;
  flex-direction: row;
  gap: 1.4rem;
  cursor: pointer;
}

.executive-details-card__image {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 100%;
  object-fit: cover;
}

.executive-details-card__title {
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 700;
  color: var(--color--secondary-205);
}

.executive-details-card__subtitle {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--color-primary-57);
}

.executive-details-card__title--disabled {
  color: var(--color-primary-70);
}
