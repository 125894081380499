.organizationDetailsModal {
  padding: 7.85rem 6.8rem 8.8rem 7.8rem;
  width: 70rem;
}

/*Header*/
.headingAndCloseIconContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modalHeading {
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-size: 2.8rem;
  line-height: 3.8rem;
  margin: 0;
  margin-bottom: 1.85rem;
}
.closeIcon {
  color: var(--color-primary-56);
  font-size: 3.5rem;
  line-height: 4.2rem;
}
.modalDesc {
  color: hsla(0, 0%, 44%, 1) /*TODO: Color not generic*/;
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
  margin-bottom: 5rem;
  opacity: 0.65;
}

/*Body*/
.organizationDetailContainer {
  display: flex;
}
.organizationDetailsIndividualColumns {
  flex: 1;
}
.individualDetailContainer {
  margin-bottom: 3.8rem;
}
.individualDetailValue {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color is not generic*/
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 0;
}
.individualDetailLabel {
  color: hsla(0, 0%, 44%, 1); /*TODO: Color is not generic*/
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0;
}

/*Footer*/
.footer {
  display: flex;
  justify-content: flex-end;
}
.doneBtnContainer {
  width: 19.6rem;
}
