.container {
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* TODO: Make color generic as per theme */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
}

.title {
  color: rgba(64, 83, 97, 1);
  font-size: 2.5rem;
  line-height: 3.3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.titleDesc {
  font-size: 1.2rem;
  color: rgba(112, 112, 112, 1);
  line-height: 1.6rem;
  opacity: 0.65;
  font-family: 'Roboto', sans-serif;
  margin-top: 0.9rem;
}

.inputContainer.input {
  margin-top: 0;
}

.registerBtn {
  background-color: var(--color--secondary-205);
  color: var(--color-primary-100);
  margin-top: 4.6rem;
  margin-bottom: 5.8rem;
}
