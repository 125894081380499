/* KYC */
.kyc {
  display: grid;
  grid-template-rows: minmax(0, 1fr) var(--footer-height);
  height: 100%;
}

.kyc__body {
  display: flex;
}

.kyc-applicant-list {
  /* border: 1px solid red; */
  width: calc(100% - var(--unit-summary-card-width));
  overflow-y: auto;
  padding-right: 8.5rem;
}

.kyc-applicant {
  border-bottom: var(--primary-border);
  padding: 4.2rem 0;
}

.kyc-applicant__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kyc-applicant__header-title {
  display: flex;
}

.kyc-applicant-name-container {
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.kyc-applicant-pregress-complete {
  color: var(--color-status-success);
}

.kyc-applicant-name {
  line-height: 2.4rem;
}

.kyc-applicant-name input {
  border: none;
}

.kyc-applicant-type {
  line-height: 2.2rem;
}

.kyc-applicant__header-action-container {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.kyc-applicant__header-action-container .icon-container {
  margin-left: 2.5rem;
  width: inherit;
}

.kyc-applicant__header-action-container .icon {
  font-size: 1.8rem;
}

.kyc-applicant__header-action-container
  .icon-container.click-disabled
  .icon-delete {
  color: var(--color-primary-82);
}

.kyc-applicant-edit {
  margin-left: 3.2rem;
  display: flex;
  align-items: center;
}

.kyc-applicant-edit .icon-container {
  margin-right: 0.6rem;
  margin-left: 0;
}

.kyc-applicant-info-section {
  margin-top: 3.8rem;
}

.kyc-applicant-info-section__title {
  align-items: center;
  display: flex;
}

.kyc-applicant-info-section__title-dot-line {
  border-top: 0.2rem dashed var(--color-primary-82);
  flex-grow: 1;
  margin-left: 2.6rem;
}

.kyc-applicant-info-section__desc {
  display: inline-block;
  font-size: 1.5rem;
  max-width: 49.6rem;
}

.kyc-applicant-info-section__body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8.5rem;
  row-gap: 4.5rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 2.7rem;
}

.kyc-applicant-info-section__body--col-2 {
  grid-template-columns: 1fr 1fr;
}

.kyc-applicant-info-section__item-label {
  color: var(--color-primary-82);
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.kyc-applicant__footer {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 2.65rem;
}

.add-kyc-applicant__body {
  padding: 4.2rem 0;
  display: flex;
  justify-content: space-between;
  border-bottom: var(--primary-border);
}

.add-kyc-applicant .icon-container {
  border: 0;
  margin: 0 1.45rem;
}

.add-kyc-applicant .icon-correct.click-enabled {
  color: var(--color-primary-100);
  background: hsla(88, 90%, 41%, 1);
  font-size: 2.5rem;
}

.add-kyc-applicant .icon-cross {
  color: var(--color-primary-56);
  background: var(--color-primary-94);
  font-size: 2.5rem;
}

.add-kyc-applicant__btn {
  padding: 4.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.2rem;
  font-size: 2rem;
  color: var(--color-primary-70);
}

.kyc__footer {
  border-top: var(--primary-border);
}

/* Drop Down */
.dropdown {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  position: relative;
}

.dropdown .icon {
  font-size: 2rem;
}

.dropdown-list {
  background: var(--color-primary-100);
  border-radius: 1rem;
  /* box-shadow: 0px 0.8rem 1.6rem 0px rgb(0 0 0 / 20%); */
  box-shadow: 0px 0.8rem 1.6rem 0px rgba(0, 0, 0, 20%);
  left: 1.5rem;
  max-height: 30rem;
  max-width: 60rem;
  min-width: 21.5rem;
  overflow: auto;
  padding: 2rem;
  position: absolute;
  top: 3rem;
  width: max-content;
  z-index: 10;
}

.dropdown-list__item {
  border: 0;
  color: var(--color-primary-56);
  display: block;
  padding: 1rem 1.6rem;
  padding-left: 0;
  margin: 0 auto;
  text-align: left;
  background: inherit;
}

.dropdown-list__item:hover {
  background: red;
}

.dropdown--secondary {
  bottom: 0;
  color: var(--color-accent);
  position: absolute;
  width: 5.4rem;
}

/* Inputs */
.kyc-applicant .input {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: var(--primary-border);
  max-width: 23rem;
}

.kyc-applicant .input__error {
  position: absolute;
  top: 6rem;
  color: red;
}

.kyc-applicant .input_label {
  color: var(--color-primary-82);
  font-size: 1.2rem;
}

.kyc-applicant .input_field {
  align-self: flex-end;
  background-color: transparent;
  border: 0;
  color: var(--color-primary-56);
  padding: 0;
  padding-bottom: 0.5rem;
  width: 100%;
  margin-top: 1.2rem;
}

.kyc-applicant .input:focus-within {
  border-color: var(--color-accent);
}

.kyc-applicant .input--secondary .input_field {
  padding-left: 6.7rem;
}

.kyc-applicant input[type='date']::-webkit-calendar-picker-indicator {
  color: var(--color-accent);
  opacity: 1;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-width: thin;
}

.kyc-applicant .input--upload {
  max-width: inherit;
  justify-content: space-around;
}

.kyc-applicant .input--upload-width-till-end {
  grid-column: 2 / -1;
}

.input--upload .filename-label {
  color: var(--color-primary-56);
  cursor: pointer;
  margin-top: 1.2rem;
  padding-bottom: 0.5rem;
}

.icon--upload {
  position: absolute;
  right: 0;
  bottom: 0.5rem;
  font-size: 2rem;
  color: var(--color-accent);
}

.icon--upload-success {
  color: var(--color-primary-56);
}

.link-terms-conditions {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0;
  padding: 1rem 3rem 1rem 1rem;
  text-align: right;
  color: var(--color-primary-56);
}

.kyc-agree-checkbox .checkbox > input:checked + span::before {
  left: 0.1rem;
  top: 1.3rem;
}

.kyc-save-and-pay {
  margin: 0 3.5rem 0 0;
}

.kyc-save-and-pay.click-disabled {
  color: var(--color-primary-56);
}

.thumb .img-fluid {
  background-color: var(--color-primary-94);
}

::placeholder {
  color: var(--color-primary-70);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-primary-70);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-primary-70);
}
.block-hide {
  visibility: hidden;
}
.kyc-add-name::placeholder {
  font-weight: 500;
}
