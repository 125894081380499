.galleryWrapper {
  display: flex;
  flex-flow: column;
  padding-top: 0rem;
  padding: 0 2rem 2rem 2rem;
}
.smallTiles {
  display: flex;
  gap: 2%;
  margin-top: 0.5rem;
  overflow: hidden;
  position: relative;
}
.galleryWrapper img {
  border-radius: 1rem;
  display: block;
  width: 100%;
  max-width: 23%;
  min-width: 23%;
  max-height: 7.5rem;
  min-height: 7.5rem;
}
.smallTiles .galleryWrapper img {
  min-height: 8rem;
  max-height: 8rem;
  min-height: 8rem;
}
.overlayBg {
  align-items: center;
  /* TODO: Color is not generic */
  background-color: hsla(0, 0%, 91%, 0.8);
  border-radius: 1rem;
  color: var(--color-cp-primary-17);
  display: flex;
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  height: 105%;
  justify-content: center;
  max-width: 8rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.subHeading {
  color: var(--color-cp-primary-31);
  font-family: 'SF-UI-semibold', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
  /* padding: 0 1.8rem; */
}
