.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9.4rem;
}
.cpName {
  font-size: 4rem;
  line-height: 6rem;
  color: var(--color--secondary-205);
  font-family: 'poppins';
  font-weight: 600;
  margin: 0;
}
.userDesignation {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--color-primary-70);
}
.cpDetailsContainer {
  display: flex;
  justify-content: flex-start;
}
.cpDetailContainer {
  display: flex;
  justify-content: center;
}
.cpDetailContainer:first-of-type {
  margin-right: 5.1rem;
}
.phoneIcon {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--color-primary-56);
  margin-right: 0.8rem;
}
.cpPhoneNumber {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: var(--color-primary-56);
}
.statsContainer {
  display: flex;
}
.individualStatContainer {
  margin-right: 6rem;
}
.statsValues {
  font-size: 6rem;
  line-height: 7.2rem;
  color: var(--color-primary-19);
  margin-top: -1.5rem;
}
.statsDetails {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-primary-56);
}

.statsDecline {
  color: hsla(0, 100%, 80%, 1); /*TODO: Color not generic*/
}
.statContain {
  color: hsla(40, 100%, 75%, 1); /*TODO: Color not generic*/
}
.statIncrease {
  color: hsla(88, 53%, 68%, 1); /*TODO: Color not generic*/
}
